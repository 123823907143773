import { Box, Button, Input, Modal, ModalClose, ModalDialog, Option, Select, Typography } from "@mui/joy";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { addTeacherInfo } from "../../../../../Store/slices/batchSlice";
import { UserRole } from "../../../../../Constants/user-role";
import { addTeacherThunk, assignTeacherThunk } from "../../../../../Store/actions/authenticateAction";
import { fetchTeachersByTenant } from "../../../../../Store/actions/userAction";
import { getFirstLetterCapitalized } from "../../../../../helpers/text-formatter";
import { showToast } from "../../../../../Store/slices/commonSlice";
import { ToastType } from "../../../../../Constants/toast-type";

const AddTeacher = ({ batchId, setIsOpen, isOpen }) => {
	const [teacherName, setTeacherName] = useState("");
	const [teacherEmail, setTeacherEmail] = useState("");
	const [loading, setLoading] = useState(false);
	const [step, setSteps] = useState(1);
	const [teacherId, setTeacherId] = useState(null);

	const { batchDetails, selectedTenant, teacherInfos } = useSelector((state) => state.batch);
	const { user } = useSelector((state) => state.user);

	const { allTeachers } = useSelector((state) => state.tenant);

	const dispatch = useDispatch();
	const handleClose = () => {
		setTeacherName("");
		setTeacherEmail("");
		setTeacherId(null);
		setLoading(false);
		setIsOpen(false);
	};

	const handleTeacherChange = (e, newValue) => {
		setTeacherId(newValue);
	};

	const handleSubmit = async () => {
		if (step === 1) {
			const teacherData = {
				batch_id: batchId || batchDetails?.data?.batch_id,
				instructor_id: teacherId,
			};
			setLoading(true);
			const response = await dispatch(assignTeacherThunk(teacherData));
			if (response.payload.data) {
				dispatch(addTeacherInfo(response.payload.data.instructors));
				setLoading(false);
				setIsOpen(false);
				setTeacherId(null);
			}
		} else if (step === 2) {
			const teacherData = {
				batch_id: batchId || batchDetails?.data?.batch_id,
				name: teacherName,
				email: teacherEmail,
				role: UserRole.TEACHER,
				tenant_id: user?.tenant_id || selectedTenant || batchDetails?.data?.courseDetails[0]?.tenant_id,
			};
			setLoading(true);
			const response = await dispatch(addTeacherThunk(teacherData));
			if (response.payload.data) {
				dispatch(addTeacherInfo(response.payload.data));
				setTeacherName("");
				setTeacherEmail("");
				setTeacherId(null);
				setLoading(false);
				setIsOpen(false);
			} else if (response.payload.message) {
				dispatch(showToast({ toastMessage: response.payload.message, toastType: ToastType.ERROR }));
				setTeacherName("");
				setTeacherEmail("");
				setTeacherId(null);
				setLoading(false);
				setIsOpen(false);
			}
		}
	};

	const checkDisabled = () => {
		if (step === 1) {
			return !teacherId;
		} else if (step === 2) {
			return !teacherName || !teacherEmail;
		}
	};

	return (
		<Modal
			open={isOpen}
			onClose={handleClose}
		>
			<ModalDialog>
				<ModalClose />
				<Typography level='h4'>Add a Teacher</Typography>
				<Box
					sx={{
						width: 600,
						display: "flex",
						flexDirection: "column",
						gap: 2,
					}}
				>
					{step === 1 && (
						<Box>
							<Select
								placeholder='Choose from an existing teacher'
								onChange={handleTeacherChange}
							>
								{allTeachers.map((teacher) => (
									<Option
										value={teacher.instructor_id}
										disabled={teacherInfos?.data?.some((teacherInfo) => teacherInfo.instructors.instructor_id === teacher.instructor_id)}
									>
										{getFirstLetterCapitalized(teacher.name)}
									</Option>
								))}
							</Select>
						</Box>
					)}
					{step === 2 && (
						<>
							<Box>
								<Typography
									level='h6'
									sx={{ mb: 1, color: "#050519", fontSize: "14px", fontWeight: "600" }}
								>
									Teacher Name
								</Typography>
								<Input
									type='text'
									value={teacherName}
									slotProps={{
										input: {
											minLength: 3,
											maxLength: 50,
										},
									}}
									onChange={(e) => setTeacherName(e.target.value)}
								/>
							</Box>
							<Box>
								<Typography
									level='h6'
									sx={{ mb: 1, color: "#050519", fontSize: "14px", fontWeight: "600" }}
								>
									Teacher Email
								</Typography>
								<Input
									type='email'
									value={teacherEmail}
									slotProps={{
										input: {
											pattern: "[a-z0-9._%+-]+@[a-z0-9.-]+.[a-z]{2,4}",
										},
									}}
									onChange={(e) => setTeacherEmail(e.target.value)}
								/>
							</Box>
						</>
					)}
					<Box
						sx={{
							display: "flex",
							alignSelf: "flex-end",
							gap: 2,
						}}
					>
						{step === 1 && (
							<Button
								sx={{ bgcolor: "white", mb: 1, color: "#3838f1", border: "1px solid #f4f4f4" }}
								variant='outlined'
								onClick={() => setSteps(2)}
							>
								Add Teacher Manually
							</Button>
						)}
						{step === 2 && (
							<Button
								sx={{ bgcolor: "white", mb: 1, color: "#3838f1", border: "1px solid #f4f4f4" }}
								variant='outlined'
								onClick={() => setSteps(1)}
							>
								Choose from existing teachers
							</Button>
						)}
						<Button
							sx={{ mb: 1, bgcolor: "#3838f1", color: "white", alignSelf: "flex-end" }}
							color='primary'
							disabled={checkDisabled()}
							onClick={handleSubmit}
							loading={loading}
						>
							Add Teacher
						</Button>
					</Box>
				</Box>
			</ModalDialog>
		</Modal>
	);
};

export default AddTeacher;

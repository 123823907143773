import { Box, Button, Input, Typography, IconButton } from "@mui/joy";
import UploadIcon from "@mui/icons-material/Upload";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { updateMaterialThunk } from "../../../../Store/actions/materialAction";
import { updateReference } from "../../../../Store/slices/referencesSlice";
import { Link } from "react-router-dom";

const ReferenceEditForm = ({ material, setShowReferenceEditModal }) => {
	const [referenceName, setReferenceName] = useState(material?.name);
	const [referenceUrl, setReferenceUrl] = useState(material?.url);
	const [loading, setLoading] = useState(false);
	const [isEditing, setIsEditing] = useState(false);
	const [files, setFiles] = useState();


	const dispatch = useDispatch();

	// Handle file upload logic here
	const handleFileChange = (e) => {

		const uploadedFile = e.target.files[0];

		if (uploadedFile) {
			// Set the reference URL or file name
			setReferenceUrl(uploadedFile.name); 

			// store the file 
			setFiles(uploadedFile);

			// Switch off editing mode after file selection
			setIsEditing(false);
		}
	};


	const handleRemoveFile = () => {
		// Set editing mode to true when the file is removed
		setIsEditing(true);

		// Reset the reference URL to empty, indicating no file
		setReferenceUrl("");
	};

	// Function to handle URL change
	const handleUrlChange = (e) => {
		setReferenceUrl(e.target.value);
	};



	const handleSubmit = async (file) => { // Added file as a parameter
		if (
			referenceName === "" ||
			referenceUrl === "" ||
			(referenceName?.trim() === material?.name?.trim() &&
				referenceUrl.trim() === material?.url.trim())
		) {
			return;
		}

		const data = {
			name: referenceName,
			url: referenceUrl,
			material_id: material?.material_id,
		};

		setLoading(true);
		const formData = new FormData(); 
		formData.append("material_id", data.material_id);
		formData.append("name", data.name);
		formData.append("url", data.url);

		if (file) { // Check if a file is provided
			formData.append("file", files); 
		}


		const response = await dispatch(updateMaterialThunk(formData)); 

		if (response?.payload?.data) {
			setLoading(false);
			dispatch(updateReference(response.payload.data));
			setShowReferenceEditModal("");
		}
	};

	return (
		<Box>
			<Box
				sx={{
					display: "flex",
					gap: 1,
					flexDirection: "column",
				}}
			>
				<Box>
					<Typography
						level='h6'
						sx={{ mb: 1, color: "#050519", fontSize: "14px", fontWeight: "600" }}
					>
						Reference Name
					</Typography>
					<Input
						placeholder='e.g. Compiler Design'
						sx={{}}
						value={referenceName}
						onChange={(e) => setReferenceName(e.target.value)}
					/>
				</Box>
				{material.refrence_type === 'FILE' ? (
					// Render Box for FILE type
					<Box
						sx={{
							display: 'flex',
							alignItems: 'center',
							justifyContent: 'space-between',
							padding: '0.5rem 1rem',
							bgcolor: '#f4f4f4',
							borderRadius: 'sm',
							gap: 1,
							mt: 1,
						}}
					>
						{isEditing ? (
							// File input when editing
							<Box
								sx={{
									alignItems: 'center',
									backgroundColor: 'white',
									borderRadius: 'md',
									p: 0.5,
									boxShadow: 1,
									width: '100%',
									border: '1px solid #f4f4f4',
								}}
							>
								<IconButton
									component='label'
									color='primary'
									sx={{
										borderRadius: 'md',
										width: '100%',
									}}
								>
									<Input
										type='file'
										slotProps={{
											input: {
												accept: '.pdf,.doc,.docx,.xlsx,.csv,.ppt,.pptx',
											},
										}}
										sx={{ display: 'none' }}
										onChange={handleFileChange}
									/>
									<Box
										sx={{
											display: 'flex',
											alignItems: 'center',
											gap: 1,
											justifyContent: 'space-between',
											width: '100%',
											padding: '0 1rem',
										}}
									>
										<UploadIcon />
										<Typography>Upload</Typography>
									</Box>
								</IconButton>
							</Box>
						) : (
							// Display the reference URL when not editing
							<Link to={referenceUrl} target='_blank' style={{ textDecoration: 'none' }}>
								<Typography sx={{ color: '#3838f1', fontWeight: '600' }}>Reference doc</Typography>
								<Typography
									sx={{
										width: '400px',
										whiteSpace: 'nowrap',
										overflow: 'hidden',
										textOverflow: 'ellipsis',
									}}
									noWrap
								>
									{referenceUrl}
								</Typography>
							</Link>
						)}

						<Box sx={{ display: 'flex', gap: 1 }}>
							<Button
								variant='outlined'
								color='neutral'
								onClick={handleRemoveFile}
							>
								Remove
							</Button>
						</Box>
					</Box>
				) : (
					// Render TextField for Web Link type
					<Box>
						<Typography
							level='h6'
							sx={{ mb: 1, color: "#050519", fontSize: "14px", fontWeight: "600" }}
						>
							Reference URL
						</Typography>
						<Input
							placeholder='e.g. https://www.geeksforgeeks.org/compiler-design/'
							sx={{}}
							value={referenceUrl}
							onChange={(e) => setReferenceUrl(e.target.value)}
						/>
					</Box>
				)}

				<Box
					sx={{
						display: "flex",
						justifyContent: "flex-end",
						gap: 1,
					}}
				>
					<Button
						variant='outlined'
						onClick={() => setShowReferenceEditModal("")}
					>
						Cancel
					</Button>
					<Button
						onClick={handleSubmit}
						disabled={loading || referenceName === "" || referenceUrl === "" || (referenceName?.trim() === material?.name?.trim() && referenceUrl?.trim() === material?.url?.trim())}
						loading={loading}
					>
						Edit
					</Button>
				</Box>
			</Box>
		</Box>
	);
};

export default ReferenceEditForm;

import { Box, IconButton, Input, Textarea, Typography, Button, Card } from "@mui/joy";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
import UploadIcon from "@mui/icons-material/Upload";
import { ExternalTaskTypes, URLTaskTypes } from "../../../../Constants/task-types";
import { ReactComponent as Github } from "../../../../Assets/github.svg";
import { addManualTaskThunk } from "../../../../Store/actions/taskAction";
import { addTask } from "../../../../Store/slices/taskDetailsSlice";
import { Chip } from "@mui/material";

const ExternalTask = ({ setOpen, setShowTaskType }) => {
	const [taskTitle, setTaskTitle] = useState("");
	const [objective, setObjective] = useState("");
	const [selectedPoint, setSelectedPoint] = useState(20);
	const [files, setFiles] = useState([]);
	const [loading, setLoading] = useState(false);
	const [externalTaskType, setExternalTaskType] = useState(ExternalTaskTypes.DOC_UPLOAD);
	const [step, setStep] = useState(1);
	const [webUrl, setWebUrl] = useState("");

	const location = useLocation();
	const dispatch = useDispatch();

	const node_id = location.pathname.split("/")[4];

	const handleFileChange = (e) => {
		const selectedFiles = Array.from(e.target.files);
		if (selectedFiles.length + files.length > 4) {
			return; // Max 4 files
		}
		let totalSize = selectedFiles.reduce((acc, file) => acc + file.size, 0);
		if (totalSize > 50000000) {
			return; // Max 50MB total
		}
		setFiles((prevFiles) => [...prevFiles, ...selectedFiles]);
	};

	const handleFileRemove = (index) => {
		const newFiles = files.filter((file, i) => i !== index);
		setFiles(newFiles);
	};

	const handleSubmit = async () => {
		if (taskTitle === "" || objective === "") return;
		let task;
		if (files.length > 0) {
			task = new FormData();
			task.append("task_title", taskTitle);
			task.append("question", objective);
			task.append("points", selectedPoint);
			task.append("node_id", node_id);
			task.append("allowed_urls", [webUrl]);
			task.append("type", externalTaskType);
			files.forEach((file) => {
				task.append("docs", file);
			});
		} else {
			task = {
				task_title: taskTitle,
				question: objective,
				points: selectedPoint,
				node_id: node_id,
				type: externalTaskType,
				allowed_urls: [webUrl],
			};
		}
		setLoading(true);

		const response = await dispatch(addManualTaskThunk(task));

		if (response?.payload?.data) {
			setLoading(false);
			dispatch(addTask(response?.payload?.data));
			setOpen(false);
		}
	};
	const renderExternalTaskOptions = () => {
		return (
			<>
				<Box>
					<Typography
						level='h6'
						sx={{ mb: 1, color: "#050519", fontSize: "14px", fontWeight: "600" }}
					>
						Submission Type
					</Typography>
					<Box
						sx={{
							display: "flex",
							gap: 2,
						}}
					>
						<Card
							variant='outlined'
							color='neutral'
							sx={{
								cursor: "pointer",
								width: "50%",
								bgcolor: `${externalTaskType === ExternalTaskTypes.DOC_UPLOAD ? "#F5f5ff" : "#f4f4f4"}`,
								maxHeight: "80px",
								border: `${externalTaskType === ExternalTaskTypes.DOC_UPLOAD ? "2px solid #3838F1" : "none"}`,
							}}
							onClick={() => setExternalTaskType(ExternalTaskTypes.DOC_UPLOAD)}
						>
							<Typography
								sx={{
									color: `${externalTaskType === ExternalTaskTypes.DOC_UPLOAD ? "#3838F1" : "black"}`,
									fontWeight: "600",
								}}
							>
								File Upload
							</Typography>

							<Typography
								sx={{
									fontSize: "12px",
								}}
							>
								Choose if Students must upload PDFs or JPGs or PNGs (max 10MB total).
							</Typography>
						</Card>
						<Card
							variant='outlined'
							color='neutral'
							sx={{
								cursor: "pointer",
								width: "50%",
								bgcolor: `${externalTaskType === ExternalTaskTypes.DOC_LINK ? "#F5f5ff" : "#f4f4f4"}`,
								maxHeight: "80px",
								border: `${externalTaskType === ExternalTaskTypes.DOC_LINK ? "2px solid #3838F1" : "none"}`,
							}}
							onClick={() => setExternalTaskType(ExternalTaskTypes.DOC_LINK)}
						>
							<Typography
								sx={{
									color: `${externalTaskType === ExternalTaskTypes.DOC_LINK ? "#3838F1" : "black"}`,
									fontWeight: "600",
								}}
							>
								Web URL
							</Typography>

							<Typography
								sx={{
									fontSize: "12px",
								}}
							>
								Choose if Students must submit a Web URL of their completed Project.
							</Typography>
						</Card>
					</Box>
				</Box>
				{externalTaskType === ExternalTaskTypes.DOC_LINK && (
					<Box
						sx={{
							my: 2,
						}}
					>
						<Typography
							level='h6'
							sx={{ mb: 1, color: "#050519", fontSize: "14px", fontWeight: "600" }}
						>
							Type of Web URL
						</Typography>
						<Box
							sx={{
								display: "flex",
								gap: 2,
								alignItems: "center",
							}}
						>
							<Card
								sx={{
									cursor: "pointer",
									border: `${webUrl === URLTaskTypes.GITHUB ? "2px solid #3838F1" : "none"}`,
									bgcolor: `${webUrl === URLTaskTypes.GITHUB ? "#F5f5ff" : "#f4f4f4"}`,
								}}
								onClick={() => setWebUrl(URLTaskTypes.GITHUB)}
							>
								<Github />
							</Card>
							<Card
								sx={{
									cursor: "pointer",
									border: `${webUrl === URLTaskTypes.JUPYTER ? "2px solid #3838F1" : "none"}`,
									bgcolor: `${webUrl === URLTaskTypes.JUPYTER ? "#F5f5ff" : "#f4f4f4"}`,
								}}
								onClick={() => setWebUrl(URLTaskTypes.JUPYTER)}
							>
								<Typography level='h4'>Jupyter Notebook</Typography>
							</Card>
							<Card
								sx={{
									cursor: "pointer",
									border: `${webUrl === "" ? "2px solid #3838F1" : "none"}`,
									bgcolor: `${webUrl === "" ? "#F5f5ff" : "#f4f4f4"}`,
								}}
								onClick={() => setWebUrl("")}
							>
								<Typography level='h4'>Any URL</Typography>
							</Card>
						</Box>
					</Box>
				)}
				<Box
					sx={{
						display: "flex",
						justifyContent: "space-between",
						alignItems: "center",
						mt: 2,
					}}
				>
					<Button
						sx={{ bgcolor: "white", mb: 1, color: "#3838f1", border: "1px solid #f4f4f4" }}
						variant='outlined'
						onClick={() => {
							setStep(1);
							setShowTaskType(true);
						}}
					>
						Previous: Task Details
					</Button>
					<Box
						sx={{
							display: "flex",
							alignItems: "center",
							justifyContent: "flex-end",
							gap: 2,
						}}
					>
						<Button
							variant='outlined'
							onClick={() => {
								setOpen(false);
							}}
							sx={{ bgcolor: "white", mb: 1, color: "#3838f1", border: "1px solid #f4f4f4" }}
						>
							Cancel
						</Button>
						<Button
							sx={{ mb: 1, bgcolor: "#3838f1", color: "white" }}
							color='primary'
							disabled={taskTitle === "" || objective === ""}
							onClick={() => {
								setShowTaskType(false);
								handleSubmit();
							}}
							loading={loading}
						>
							Add Task
						</Button>
					</Box>
				</Box>
			</>
		);
	};

	const renderExternalTask = () => {
		return (
			<Box sx={{ display: "flex", flexDirection: "column", height: "100%" }}>
				<Box sx={{ width: "100%", display: "flex", flexDirection: "column", gap: 2, mt: 1 }}>
					<Box
						sx={{
							maxHeight: "35vh",
							overflowY: "auto",
							px: 2,
						}}
					>
						<Box>
							<Typography
								level='h6'
								sx={{ mb: 1, color: "#050519", fontSize: "14px", fontWeight: "600" }}
							>
								Task Title
							</Typography>
							<Input
								label='Task title'
								type='text'
								slotProps={{
									input: {
										maxLength: 64,
									},
								}}
								value={taskTitle}
								onChange={(e) => setTaskTitle(e.target.value)}
								sx={{ width: "100%" }}
								placeholder='e.g. Binary Search'
								required
								// error={taskTitle?.trim() === "" ? "Please enter the task title" : ""}
							/>
						</Box>
						<Box
							sx={{
								my: 2,
							}}
						>
							<Typography
								level='h6'
								sx={{ mb: 1, color: "#050519", fontSize: "14px", fontWeight: "600" }}
							>
								Task Description
							</Typography>
							<Textarea
								label='Objective'
								onChange={(e) => setObjective(e.target.value)}
								minRows={5}
								maxRows={10}
								value={objective}
								type='text'
								slotProps={{
									textarea: {
										maxLength: 1024,
									},
								}}
								sx={{ width: "100%" }}
								placeholder='e.g. Compiler - Assembler Differences'
							/>
						</Box>
						<Box
							sx={{
								display: "flex",
								flexDirection: "column",
								gap: 1,
							}}
						>
							<Typography
								level='h6'
								sx={{ color: "#050519", fontSize: "14px", fontWeight: "600" }}
							>
								Documents
							</Typography>
							<Box
								sx={{
									display: "flex",
									alignItems: "center",
									border: "1px solid rgba(0,0,0,0.25)",
									borderRadius: "8px",
									p: 0.5,
								}}
							>
								<IconButton
									component='label'
									color='primary'
									sx={{
										borderRadius: "8px",
										cursor: files.length >= 4 ? "not-allowed" : "pointer",
										width: "100%",
									}}
								>
									<Input
										type='file'
										slotProps={{
											input: {
												accept: ".pdf,.doc,.docx,.xlsx,.csv,.ppt,.pptx",
												multiple: true,
											},
										}}
										sx={{ display: "none" }}
										disabled={files.length >= 4}
										onChange={handleFileChange}
									/>
									<UploadIcon />
									<Typography sx={{ marginX: 2 }}>{"Upload PDFs (max 4 files, max 50MB total)"}</Typography>
								</IconButton>
							</Box>
							{files.length > 0 && (
								<Box sx={{ display: "flex", gap: 1, my: 1, flexWrap: "wrap" }}>
									{files.map((file, index) => {
										return (
											<Chip
												label={file.name}
												onDelete={() => handleFileRemove(index)}
												sx={{
													maxWidth: "200px",
												}}
												color='info'
											/>
										);
									})}
								</Box>
							)}
						</Box>
						<Box>
							<Typography
								level='h6'
								sx={{ my: 1, color: "#050519", fontSize: "14px", fontWeight: "600" }}
							>
								Points
							</Typography>
							<Box sx={{ display: "flex", gap: 1 }}>
								{[1, 5, 10, 15, 20, 25].map((point) => (
									<Button
										key={point}
										variant='outlined'
										color='neutral'
										sx={{
											bgcolor: `${selectedPoint === point ? "#000" : "white"}`,
											color: `${selectedPoint === point ? "white" : "black"}`,
											"&:hover": {
												bgcolor: `${selectedPoint === point ? "#000" : "#f4f4f4"}`, // Change "gray" to your desired hover color
												color: `${selectedPoint === point ? "white" : "black"}`,
											},
										}}
										onClick={() => setSelectedPoint(point)}
									>
										{point}
									</Button>
								))}
							</Box>
						</Box>

						<Box
							sx={{
								display: "flex",
								alignItems: "center",
								justifyContent: "flex-end",
								gap: 2,
							}}
						>
							<Button
								variant='outlined'
								onClick={() => {
									setOpen(false);
								}}
								sx={{ bgcolor: "white", mb: 1, color: "#3838f1", border: "1px solid #f4f4f4" }}
							>
								Cancel
							</Button>
							<Button
								sx={{ mb: 1, bgcolor: "#3838f1", color: "white" }}
								color='primary'
								// disabled={taskTitle === "" || files.length === 0}
								onClick={() => {
									setStep(2);
									setShowTaskType(false);
								}}
							>
								Next:Submission Details
							</Button>
						</Box>
					</Box>
				</Box>
			</Box>
		);
	};

	const renderSteps = () => {
		switch (step) {
			case 1:
				return renderExternalTask();
			case 2:
				return renderExternalTaskOptions();

			default:
				return renderExternalTask();
		}
	};

	return renderSteps();
};

export default ExternalTask;

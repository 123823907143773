import React, { useState } from "react";
import WithAuth from "../../utils/WithAuth";
import { useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";
import { Box, Button, Input, Modal, ModalClose, ModalDialog, Tab, TabList, Tabs, Textarea, Typography } from "@mui/joy";
import Video from "../topic-components/video";
import Task from "../topic-components/task";
import References from "../topic-components/references";
import AddTopicDetials from "./add-topic-components/add-topic-details";

const AddTopic = () => {
	const location = useLocation();

	const [activeTab, setActiveTab] = useState("details");
	const [isModalOpen, setIsModalOpen] = useState(false);
	const [topicNodeId, setTopicNodeId] = useState(null);
	const [moduleInfo, setModuleInfo] = useState({});

	const courseId = location.pathname.split("/")[2];

	const dispatch = useDispatch();

	const handleTabChange = (event, newValue) => {
		// if (activeTab === "details") {
		// 	// setActiveTab(newValue);
		// }
		// if (activeTab === "videos") {
		// 	if (newValue === "details") setActiveTab(newValue);
		// }
		// if (activeTab === "tasks") {
		// 	if (newValue === "videos" || newValue === "detials") setActiveTab(newValue);
		// }
		// if (activeTab === "references") {
		// 	if (newValue === "tasks" || newValue === "videos" || newValue === "details") setActiveTab(newValue);
		// }
		setActiveTab(newValue);
	};

	const renderVideoModal = () => {
		return (
			<Modal
				open={isModalOpen}
				onClose={() => setIsModalOpen(false)}
			>
				<ModalDialog>
					<ModalClose />
					<Typography level='h5'>Add New Video</Typography>
					<Input
						placeholder='e.g. Compiler - Assembler Differences'
						label='Video Title'
						sx={{ mt: 2 }}
					/>
					<Input
						placeholder='e.g. https://vimeo.com/123456789'
						label='Video URL'
						sx={{ mt: 2 }}
					/>
					<Textarea
						minRows={3}
						placeholder='You can write the summary or create with Intertekk AI'
						label='Video Summary'
						sx={{ mt: 2 }}
					/>
					<Button sx={{ mt: 2 }}>Create AI Summary from Video</Button>
					<Box sx={{ mt: 2 }}>
						<Typography>Points</Typography>
						<Box sx={{ display: "flex", gap: 1 }}>
							{[1, 5, 10, 15, 20, 25].map((point) => (
								<Button
									key={point}
									variant='outlined'
									color='neutral'
								>
									{point}
								</Button>
							))}
						</Box>
					</Box>
					<Box sx={{ display: "flex", justifyContent: "flex-end", mt: 2 }}>
						<Button
							variant='outlined'
							color='neutral'
							sx={{ mr: 1 }}
						>
							Cancel
						</Button>
						<Button>Add Video</Button>
					</Box>
				</ModalDialog>
			</Modal>
		);
	};

	const renderDetails = () => {};

	const renderTabsInfo = (activeTab) => {
		switch (activeTab) {
			// case "details":
			// 	return (

			// 	);
			case "videos":
				return <Video setIsModalOpen={setIsModalOpen} />;

			case "tasks":
				return <Task />;

			// case "references":
			// 	return <References />;
			default:
				return renderDetails();
		}
	};

	return (
		<Box sx={{ width: "100%", maxWidth: 800, margin: "auto", p: 2 }}>
			{/* <Box sx={{ display: "flex", justifyContent: "space-between", mb: 2 }}>
				<Typography level='h6'>Create New Topic</Typography>
			</Box>

			<Tabs
				value={activeTab}
				onChange={handleTabChange}
			>
				<TabList>
					<Tab
						value='details'
						// disabled={isFromAI}
					>
						Topic Details
					</Tab>
					<Tab
						value='videos'
						// disabled={isFromAI}
					>
						Videos
					</Tab>
					<Tab value='tasks'>Tasks</Tab>
					<Tab value='references'>References</Tab>
				</TabList>
			</Tabs>

			<Box
				sx={{
					p: 2,
					border: "1px solid",
					borderColor: "divider",
					height: "30rem",
					borderRadius: "sm",
					background: "white",
				}}
			>
				{renderTabsInfo(activeTab)}
			</Box>

			{renderVideoModal()} */}
			<AddTopicDetials
				setActiveTab={setActiveTab}
				setTopicNodeId={setTopicNodeId}
			/>
		</Box>
	);
};

export default WithAuth(AddTopic);

import { Box, Typography } from "@mui/joy";
import React from "react";

const SubmissionDashboard = ({ submissionInfo }) => {
	return (
		<Box
			sx={{
				display: "flex",
				gap: 8,
				alignItems: "center",
				padding: "0 1rem",
			}}
		>
			{/* <Box
				sx={{
					display: "flex",
					flexDirection: "column",
					gap: 2,
				}}
			>
				<Typography
					sx={{
						fontWeight: "600",
						fontSize: "14px",
						color: "#050519",
						alignSelf: "flex-start",
					}}
				>
					Topics Published
				</Typography>
				<Typography
					sx={{
						fontSize: "28px",
						fontWeight: "600",
						color: "#050519",
						alignSelf: "flex-start",
					}}
				>
					{submissionInfo?.data?.totalTopicsSchduled} of {submissionInfo?.data?.totalTopics}
				</Typography>
			</Box> */}
			<Box
				sx={{
					display: "flex",
					flexDirection: "column",
					gap: 2,
				}}
			>
				<Typography
					sx={{
						fontWeight: "600",
						fontSize: "14px",
						color: "#050519",
						alignSelf: "flex-start",
					}}
				>
					Tasks Attended
				</Typography>
				<Typography
					sx={{
						fontSize: "28px",
						fontWeight: "600",
						color: "#050519",
						alignSelf: "flex-start",
					}}
				>
					{submissionInfo?.data?.StudentSubmissions?.TasksCount?.completedTasks + submissionInfo?.data?.StudentSubmissions?.TasksCount?.inProgressTasks}
				</Typography>
			</Box>
			<Box
				sx={{
					display: "flex",
					flexDirection: "column",
					gap: 2,
				}}
			>
				<Typography
					sx={{
						fontWeight: "600",
						fontSize: "14px",
						color: "#050519",
						alignSelf: "flex-start",
					}}
				>
					Tasks Completed
				</Typography>
				<Typography
					sx={{
						fontSize: "28px",
						fontWeight: "600",
						color: "#050519",
						alignSelf: "flex-start",
					}}
				>
					{submissionInfo?.data?.StudentSubmissions?.TasksCount?.completedTasks} of {submissionInfo?.data?.StudentSubmissions?.TasksCount?.totalTasks}
				</Typography>
			</Box>
			<Box
				sx={{
					display: "flex",
					flexDirection: "column",
					gap: 2,
				}}
			>
				<Typography
					sx={{
						fontWeight: "600",
						fontSize: "14px",
						color: "#050519",
						alignSelf: "flex-start",
					}}
				>
					Tasks Incomplete
				</Typography>
				<Typography
					sx={{
						fontSize: "28px",
						fontWeight: "600",
						color: "#050519",
						alignSelf: "flex-start",
					}}
				>
					{submissionInfo?.data?.StudentSubmissions?.TasksCount?.inProgressTasks}
				</Typography>
			</Box>
			<Box
				sx={{
					display: "flex",
					flexDirection: "column",
					gap: 2,
				}}
			>
				<Typography
					sx={{
						fontWeight: "600",
						fontSize: "14px",
						color: "#050519",
						alignSelf: "flex-start",
					}}
				>
					Tasks Not Done
				</Typography>
				<Typography
					sx={{
						fontSize: "28px",
						fontWeight: "600",
						color: "#050519",
						alignSelf: "flex-start",
					}}
				>
					{submissionInfo?.data?.StudentSubmissions?.TasksCount?.pendingTasks}
				</Typography>
			</Box>
			<Box
				sx={{
					display: "flex",
					flexDirection: "column",
					gap: 2,
				}}
			>
				<Typography
					sx={{
						fontWeight: "600",
						fontSize: "14px",
						color: "#050519",
						alignSelf: "flex-start",
					}}
				>
					Total Progress Percentage
				</Typography>
				<Typography
					sx={{
						fontSize: "28px",
						fontWeight: "600",
						color: "#050519",
						alignSelf: "flex-start",
					}}
				>
					{submissionInfo?.data?.totalPercentage} %
				</Typography>
			</Box>
			<Box
				sx={{
					display: "flex",
					flexDirection: "column",
					gap: 2,
				}}
			>
				<Typography
					sx={{
						fontWeight: "600",
						fontSize: "14px",
						color: "#050519",
						alignSelf: "flex-start",
					}}
				>
					Total Points Earned
				</Typography>
				<Typography
					sx={{
						fontSize: "28px",
						fontWeight: "600",
						color: "#050519",
						alignSelf: "flex-start",
					}}
				>
					{submissionInfo?.data?.totalPoints}
				</Typography>
			</Box>
		</Box>
	);
};

export default SubmissionDashboard;

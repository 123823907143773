import { Box, Button, Input, Typography } from "@mui/joy";
import { FormControlLabel, styled, TextField } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { generateOtpThunk, verifyOtpThunk } from "../../Store/actions/authenticateAction";
import { addUserData, setLogo } from "../../Store/slices/userSlice";
import { useNavigate } from "react-router-dom";
import { UserRole } from "../../Constants/user-role";
import { useSelector } from "react-redux";
import Logo from "../../Assets/interleap.png";
import LoginBackground from "../../Assets/Login_Background.png";
import "./login.css";
import { showToast } from "../../Store/slices/commonSlice";
import { ToastType } from "../../Constants/toast-type";
import { getTenantByIdThunk } from "../../Store/actions/tenantAction";

const Login = () => {
	const [instructor, setInstructor] = useState(null);
	const [otp, setOtp] = useState(null);
	const [email, setEmail] = useState(null);
	const [error, setError] = useState(null);
	const [otpError, setOtpError] = useState("");

	const validateOtp = (otp) => {
		return /^\d{6}$/.test(otp); // regex to ensure exactly 6 digits
	};
	const [loading, setLoading] = useState(false);
	const navigate = useNavigate();
	const { user } = useSelector((state) => state.user);

	useEffect(() => {
		if (localStorage.getItem("token") && (user?.role === UserRole.ADMIN || user?.role === UserRole.TENANT)) {
			navigate("/course");
		} else if (localStorage.getItem("token") && user?.role === UserRole.TEACHER) {
			navigate("/batches");
		}
	}, []);

	const dispatch = useDispatch();

	const validateEmail = (email) => {
		const pattern = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g;
		return pattern.test(email);
	};

	const handleSubmit = async (e) => {
		e.preventDefault();
		if (!instructor && email) {
			if (!validateEmail(email)) {
				setError("Please enter a valid email");
				return;
			}

			setLoading(true);
			const response = await dispatch(generateOtpThunk(email.toLowerCase()));

			if (response?.error) {
				dispatch(showToast({ toastMessage: response?.payload?.message, toastType: ToastType.ERROR }));
			}

			setLoading(false);
			if (response?.payload?.data) {
				setInstructor(response?.payload?.data);
			}
		}
		if (instructor && otp) {
			if (!validateOtp(otp)) {
				setOtpError("Please enter a valid OTP");
				return;
			}
			setLoading(true);
			const response = await dispatch(verifyOtpThunk({ instructor_id: instructor?.instructor_id, otp }));

			if (response?.error) {
				dispatch(showToast({ toastMessage: response?.payload?.message, toastType: ToastType.ERROR }));
			}
			setLoading(false);
			if (response?.payload?.data) {
				dispatch(addUserData(response?.payload?.data?.instructor_data));
				localStorage.setItem("token", response?.payload?.data?.token);
				if (response?.payload?.data?.instructor_data?.role === UserRole.TEACHER) {
					const tenantInfo = await dispatch(getTenantByIdThunk(response?.payload?.data?.instructor_data?.tenant_id));
					if (tenantInfo.payload.data) {
						dispatch(setLogo(tenantInfo.payload.data.company_logo));
						navigate("/batches");
					}
				} else {
					if (response?.payload?.data?.instructor_data?.tenant_id) {
						const tenantInfo = await dispatch(getTenantByIdThunk(response?.payload?.data?.instructor_data?.tenant_id));
						if (tenantInfo.payload.data) {
							dispatch(setLogo(tenantInfo.payload.data.company_logo));
							navigate("/course");
						}
					} else {
						navigate("/course");
					}
				}
			}
		}
	};

	const CssTextField = styled(TextField)({
		"& label.Mui-focused": {
			color: "#000",
		},
		"& .MuiInput-underline:after": {
			borderBottomColor: "#B2BAC2",
		},

		"& .MuiOutlinedInput-root": {
			backgroundColor: "white",

			// "& fieldset": {
			// 	borderColor: "#E0E3E7",
			// },
			// "&:hover fieldset": {
			// 	borderColor: "#B2BAC2",
			// },
			// "&.Mui-focused fieldset": {
			// 	borderColor: "#6F7E8C",
			// },
		},
	});

	return (
		<Box sx={{ height: "100vh", display: "flex", alignItems: "center", justifyContent: "center" }}>
			<Box
				component='form'
				onSubmit={handleSubmit}
				sx={{
					borderRadius: "10px",
					boxShadow: 2,
					backgroundImage: `url(${LoginBackground})`,
					p: 4,
					py: 6,
					backgroundSize: "cover",
					backgroundRepeat: "no-repeat",
					backgroundPosition: "center",
					width: "600px",
					height: "600px",
					display: "flex",
					flexDirection: "column",
					gap: 2,
				}}
			>
				<Box sx={{ mt: 5 }}>
					<div
						className='company-name'
						style={{ marginBottom: 6, zIndex: 1000, width: "200px" }}
					>
						<img
							src={Logo}
							style={{
								maxWidth: "100%",
							}}
						/>
					</div>
				</Box>

				{instructor ? (
					<Box sx={{ display: "flex", flexDirection: "column", alignItems: "center", gap: 2, my: 5, p: 3 }}>
						<Input
							label='Email'
							value={email}
							readOnly
							sx={{ py: 1, width: "70%" }}
							placeholder='Enter your email'
						/>

						<Typography sx={{ color: "white", display: "flex", textAlign: "left" }}>Enter your otp</Typography>
						<Input
							label='OTP'
							type='number'
							sx={{ py: 1, width: "70%" }}
							onChange={(e) => setOtp(e.target.value)}
							placeholder='Enter OTP'
							error={!!otpError}
						/>
						{otpError && (
							<Typography
								level='body2'
								sx={{ color: "red", mt: 1 }}
							>
								{otpError}
							</Typography>
						)}
					</Box>
				) : (
					<Box sx={{ p: 3, display: "flex", flexDirection: "column", gap: 2, my: 4, alignItems: "center" }}>
						<Typography
							level='h2'
							sx={{ color: "white" }}
						>
							Enter your email
						</Typography>
						<Input
							label='Email'
							sx={{ py: 1, width: "70%" }}
							onChange={(e) => setEmail(e.target.value) || setError(null)}
							placeholder='Enter your email'
							type='email'
							slotProps={{
								input: {
									pattern: "[a-z0-9._%+-]+@[a-z0-9.-]+.[a-z]{2,4}$",
								},
							}}
							color='info'
						/>
						{error && (
							<Typography
								level='body2'
								sx={{ color: "red", mt: 0.5 }}
							>
								{error}
							</Typography>
						)}
					</Box>
				)}

				<Box sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
					<Button
						type='submit'
						loading={loading}
						variant='contained'
						sx={{
							bgcolor: "#F5F5FF",
							color: "#000",
							fontWeight: "bold",
							fontSize: "1rem",
							width: "40%",
						}}
					>
						{instructor ? "Login" : "Send OTP"}
					</Button>
				</Box>
			</Box>
		</Box>
	);
};

export default Login;

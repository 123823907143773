import { Box } from "@mui/joy";
import React from "react";
import CodeEditForm from "./code-edit-form";
import MCQEditForm from "./mcq-edit-form";
import DocEditForm from "./doc-edit-form";
import ExternalEditForm from "./external-edit-form";
import VideoEditForm from "../edit-task/video-edit-form";

const TaskEditModal = ({ setOpen, task }) => {
	const renderTaskForm = () => {
		const { task_type } = task;
		switch (task_type) {
			case "MCQ":
				return (
					<MCQEditForm
						setOpen={setOpen}
						task={task}
					/>
				);
			case "CODE":
				return (
					<CodeEditForm
						setOpen={setOpen}
						task={task}
					/>
				);
			case "DOC":
				return (
					<DocEditForm
						setOpen={setOpen}
						task={task}
					/>
				);
			case "DOC_UPLOAD":
				return (
					<ExternalEditForm
						setOpen={setOpen}
						task={task}
					/>
				);
			case "DOC_LINK":
				return (
					<ExternalEditForm
						setOpen={setOpen}
						task={task}
					/>
				);
			case "VID":
				return (
					<VideoEditForm
						setOpen={setOpen}
						task={task}
					/>
				);
			default:
				return null;
		}
	};

	return <Box>{renderTaskForm()}</Box>;
};

export default TaskEditModal;

import axios from "axios";
import { API_URL } from "../../Config";

const fetchToken = () => {
	return localStorage.getItem("token");
};

export const fetchBatches = async (query) => {
	try {
		const queryString = new URLSearchParams(query).toString();
		const response = await axios.get(`${API_URL}/batch/admin?${queryString}`, {
			headers: {
				Authorization: `Bearer ${fetchToken()}`,
			},
		});
		return response.data;
	} catch (error) {
		console.error(error);
	}
};

export const addBatch = async (batch) => {
	const response = await axios.post(`${API_URL}/batch/admin`, batch, {
		headers: {
			Authorization: `Bearer ${fetchToken()}`,
		},
	});

	return response.data;
};

export const fetchStudentsByBatchId = async (batchId, query) => {
	const queryString = new URLSearchParams(query).toString();
	const response = await axios.get(`${API_URL}/student/admin/batch?batch_id=${batchId}&${queryString}`, {
		headers: {
			Authorization: `Bearer ${fetchToken()}`,
		},
	});
	return response.data;
};

export const fetchStudentById = async (studentId) => {
	const response = await axios.get(`${API_URL}/student/student?student_id=${studentId}`, {
		headers: {
			Authorization: `Bearer ${fetchToken()}`,
		},
	});
	return response.data;
};

export const addStudentsAndAssignToBatch = async (students, batchId) => {
	const response = await axios.post(
		`${API_URL}/student/admin/assign`,
		{
			studentInfos: students,
			batch_id: batchId,
		},
		{
			headers: {
				Authorization: `Bearer ${fetchToken()}`,
			},
		}
	);
	return response.data;
};

export const fetchBatchById = async (batchId) => {
	const response = await axios.get(`${API_URL}/batch/admin/${batchId}`, {
		headers: {
			Authorization: `Bearer ${fetchToken()}`,
		},
	});
	return response.data;
};

export const updateBatchById = async (batch) => {
	const response = await axios.patch(
		`${API_URL}/batch/admin`,
		batch,

		{
			headers: {
				Authorization: `Bearer ${fetchToken()}`,
			},
		}
	);
	return response.data;
};

export const deleteBatch = async (batchId) => {
	const response = await axios.delete(`${API_URL}/batch/admin?batch_id=${batchId}`, {
		headers: {
			Authorization: `Bearer ${fetchToken()}`,
		},
	});
	return response.data;
};

export const publishBatch = async (batch_id) => {
	const response = await axios.post(
		`${API_URL}/batch/admin/publish`,
		{ batch_id },
		{
			headers: {
				Authorization: `Bearer ${fetchToken()}`,
			},
		}
	);
	return response.data;
};

export const duplicateBatch = async (batch_id) => {
	const response = await axios.post(
		`${API_URL}/batch/admin/duplicate`,
		{ batch_id },
		{
			headers: {
				Authorization: `Bearer ${fetchToken()}`,
			},
		}
	);
	return response.data;
};

export const fetchStudentSubmission = async (studentId, batchId) => {
	const reponse = await axios.get(`${API_URL}/student/admin/submissions?student_id=${studentId}&batch_id=${batchId}`, { headers: { Authorization: `Bearer ${fetchToken()}` } });
	return reponse.data;
};

import axios from "axios";
import { API_URL } from "../../Config";

const fetchToken = () => {
	return localStorage.getItem("token");
};

export const createEventForBatch = async (event) => {
	const response = await axios.post(`${API_URL}/batch-event/admin`, event, {
		headers: {
			Authorization: `Bearer ${fetchToken()}`,
		},
	});
	return response.data;
};

export const fetchEventsByBatchId = async (batch_id) => {
	const response = await axios.get(`${API_URL}/batch-event/admin/${batch_id}`, {
		headers: {
			Authorization: `Bearer ${fetchToken()}`,
		},
	});
	return response.data;
};

export const deleteEventById = async (eventID) => {
	const response = await axios.delete(`${API_URL}/batch-event/${eventID}`, {
		headers: {
			Authorization: `Bearer ${fetchToken()}`,
		},
	});
	return response.data;
};

export const editEventForBatch = async (event) => {
	const response = await axios.patch(`${API_URL}/batch-event/edit_event`, event, {
		headers: {
			Authorization: `Bearer ${fetchToken()}`,
		},
	});
	return response.data;
};


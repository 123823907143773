const colorObj = {
	COMPLETED: "#509b64",
	IN_PROGRESS: "#FFC237",
	PENDING: "#FF5C5C",
	ACTIVE: "#D48134",
};
const bgColorObj = {
	COMPLETED: "#E1F7E7",
	IN_PROGRESS: "#FFF7E1",
	PENDING: "#FFECEC",
};

const studentStatusColor = {
	Active: "#D48134",
	Invited: "#509b64",
	"To be invited": "#FF5C5C",
};

export { colorObj, bgColorObj, studentStatusColor };

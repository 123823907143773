import { Link } from "@mui/material";
import React, { useEffect } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { Box, Typography } from "@mui/joy";
import { useDispatch, useSelector } from "react-redux";
import { getTopicInfoFromNode } from "../../helpers/node";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { fetchStudentByIdThunk } from "../../Store/actions/batchAction";

const BackButton = () => {
	const navigate = useNavigate();
	const location = useLocation();
	const dispatch = useDispatch();
	const params = useParams();

	const { courseData } = useSelector((state) => state.course);
	const { batchDetails } = useSelector((state) => state.batch);
	const { studentInfo } = useSelector((state) => state.student);

	let topicId = null,
		courseId = null,
		batchId = null,
		studentId = null,
		parentNodeId = null;

	if (location?.search) {
		parentNodeId = location.search.split("=")[1];
	}

	const paramInfo = params["*"].split("/");

	if (paramInfo.length >= 2) {
		if (paramInfo[0] === "course") {
			courseId = paramInfo[1];
			if (paramInfo.length >= 4) {
				topicId = paramInfo[3];
			}
		} else if (paramInfo[0] === "batches") {
			batchId = paramInfo[1];
			if (paramInfo.length >= 4) {
				studentId = paramInfo[3];
			}
		}
	}

	const pathnames = location.pathname.split("/").filter((x) => x);

	const excludedPath = ["addstudent"];
	// Don't show the button on the home page ,add course page ,add student and  add topic page
	if (location.pathname === "/" || pathnames.some((path) => excludedPath.includes(path))) {
		return null;
	}

	const getPathName = (index) => {
		return "/" + pathnames.splice(0, index + 1).join("/");
	};

	return (
		<Box
			sx={{
				display: "flex",
				alignItems: "center",
				my: 2,
				width: "fit-content",
				paddingX: "10px",
				paddingY: "5px",
				borderRadius: "10px",
				backgroundColor: "#ebebeb",
			}}
		>
			{pathnames.map((name, index) => {
				let listText = "";
				if (name === "course") {
					listText = "Courses";
				} else if (courseId && parseInt(name) === parseInt(courseId)) {
					listText = courseData?.data?.name;
				} else if (name === "batches") {
					listText = "Batches";
				} else if (batchId && parseInt(name) === parseInt(batchId)) {
					listText = batchDetails?.data?.name;
				} else if (name === "addNewBatch") {
					listText = "New Batch";
				} else if (topicId && parseInt(name) === parseInt(topicId) && courseData?.data) {
					const topicInfo = getTopicInfoFromNode(courseData?.data, topicId);
					listText = topicInfo.node_name;
				} else if (studentId && parseInt(name) === parseInt(studentId)) {
					listText = "Student Details";
				} else if (name === "addcourse") {
					listText = "Add New Course";
				} else if (name === "viewBatch") {
					listText = "Batch Details";
				} else if (name === "addTopic") {
					listText = `Add Topic`;
				}

				const isCurrent = pathnames.length - 1 === index;
				const isVisited = index < pathnames.length - 1;
				return (
					listText && (
						<Box>
							<Link
								key={index}
								onClick={() => navigate(getPathName(index))}
								sx={{
									display: "flex",
									alignItems: "center",
									textDecoration: "none",
									color: isCurrent ? "#3838f1" : "black", // Highlight the current path
									fontWeight: isVisited ? "bold" : "normal", // M
								}}
							>
								<Typography
									variant='h6'
									sx={{ cursor: "pointer" }}
								>
									{listText}
								</Typography>
								{pathnames.length - 1 !== index && (
									<ChevronRightIcon
										sx={{
											color: "gray",
											mx: 1,
										}}
										fontSize='10px'
									/>
								)}
							</Link>
						</Box>
					)
				);
			})}
		</Box>
	);
};

export default React.memo(BackButton);

import { Box, Input, Typography } from "@mui/joy";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { convertToInputDate } from "../../../../helpers/dateTime";
import { UserRole } from "../../../../Constants/user-role";
import { useDispatch } from "react-redux";
import { updateBatchByIdThunk } from "../../../../Store/actions/batchAction";
import { DateTime } from "luxon";
import Loader from "../../../utils/Loader";
import { setUpdatedBatchInfo } from "../../../../Store/slices/batchSlice";
import { showToast } from "../../../../Store/slices/commonSlice";
import { ToastType } from "../../../../Constants/toast-type";

const BatchDetails = () => {
	const { batchDetails, batchLoading } = useSelector((state) => state.batch);
	const { topicSessions } = useSelector((state) => state.topic);

	const { user } = useSelector((state) => state.user);
	const [startDate, setStartDate] = useState(null);
	const [endDate, setEndDate] = useState(null);
	const [batchName, setBatchName] = useState(null);
	const [externalBatchId, setExternalBatchId] = useState("");

	const dispatch = useDispatch();

	useEffect(() => {
		setStartDate(batchDetails?.data?.start_date);
		setEndDate(batchDetails?.data?.end_date);
		setBatchName(batchDetails?.data?.name);
		setExternalBatchId(batchDetails?.data?.external_batch_id);
	}, [batchDetails]);

	const isBatchNameValid = batchName?.length > 0 && batchName?.length <= 64;
	const isExternalBatchIdValid = /^[a-zA-Z0-9-_/]{1,32}$/.test(externalBatchId);

	const handleSubmit = async () => {
		const format = "d/M/yyyy";
		let updatedInfo = {};

		// Compare current state values with batchDetails and prepare the updated info
		if (startDate !== batchDetails?.data?.start_date) {

			// // Check if the entered date is less than current date
			if (startDate < batchDetails?.data?.start_date) {
				alert("You cannot select a past date."); // Notify the user
				setStartDate(batchDetails?.data?.start_date); //reset
			}

			//here if we are allowing start date to be scheduled to previous dates , then we need to check the start date should be always lesser than equal to any schedule start date of the topic session
			// i have array of topic sessions
			// i will check if the start date is greater than any of the topic session start date
			// if yes then i will show error
			// else i will update the start date
			if (topicSessions.length > 0) {
				const topicSessionStartDate = topicSessions.map((topic) => topic.start_date);

				const isStartDateValid = topicSessionStartDate.every((date) => {
					return DateTime.fromISO(startDate, format) <= DateTime.fromISO(date, format);
				});

				if (!isStartDateValid) {
					setStartDate(batchDetails?.data?.start_date);
					return;
				}
			}
			updatedInfo.start_date = DateTime.fromISO(startDate, format).toUTC().toISO();
		}

		if (endDate !== batchDetails?.data?.end_date) {
			updatedInfo.end_date = DateTime.fromISO(endDate, format).set({ hour: 23, minute: 59, second: 0, millisecond: 0 }).toUTC().toISO();
		}

		if (batchName !== batchDetails?.data?.name && isBatchNameValid) {
			updatedInfo.name = batchName;
		}

		if (externalBatchId !== batchDetails?.data?.external_batch_id && isExternalBatchIdValid) {
			updatedInfo.external_batch_id = externalBatchId;
		}

		// If any changes have been made, update the batch info
		if (Object.keys(updatedInfo).length > 0) {
			updatedInfo.batch_id = batchDetails?.data?.batch_id;
			dispatch(setUpdatedBatchInfo(updatedInfo));
		}
	};

	if (batchLoading) {
		return (
			<Box
				sx={{
					display: "flex",
					justifyContent: "center",
					alignItems: "center",
				}}
			>
				<Loader />
			</Box>
		);
	}

	return (
		<Box sx={{ display: "flex", flexDirection: "column" }}>
			<Box
				sx={{
					display: "flex",
					alignItems: "center",
					justifyContent: "space-between",
				}}
			>
				<Box
					sx={{ mx: 1, mb: 2, width: "50%" }}
				>
					<Typography sx={{ textAlign: "start", mb: 1, fontWeight: "600" }}>External Batch Id</Typography>
					<Input
						value={externalBatchId}
						placeholder='Enter External batch id'
						sx={{ mb: 2 }}
						onChange={(e) => setExternalBatchId(e.target.value)}
						readOnly={user?.role === UserRole.TEACHER}
						slotProps={{
							input: {
								pattern: "[a-zA-Z0-9-_/]{1,16}",
								required: true,
								maxLength: 32,
							},
						}}
						error={!isExternalBatchIdValid && externalBatchId?.length > 0}
						disabled={user?.role === UserRole.TEACHER}
						onBlur={handleSubmit}
					/>
					{!isExternalBatchIdValid && (
						<Typography
							color='danger'
							sx={{
								fontSize: ".8rem",
								textAlign: "left",
								m: 0,
							}}
						>
							External Batch ID must be alphanumeric and '-' , '_' , '/' are allowed.
						</Typography>
					)}
				</Box>
				{(user?.role === UserRole.ADMIN) && (
					<Box sx={{ mx: 1, mb: 2, width: "50%" }}>
						<Typography sx={{ textAlign: "start", mb: 1, fontWeight: "600" }}>Tenant Name</Typography>
						<Input
							value={batchDetails?.data?.courseDetails[0]?.tenant?.name}
							sx={{ mb: 2 }}
							readOnly
							disabled
						/>
					</Box>
				)}
			</Box>

			<Box sx={{ my: 4, display: "flex", alignItems: "center", justifyContent: "space-between" }}>
				<Box sx={{ width: "50%", mx: 1 }}>
					<Typography sx={{ textAlign: "start", mb: 1, fontWeight: "600" }}>Batch Name</Typography>
					<Input
						value={batchName}
						placeholder='Enter Batch Name'
						onChange={(e) => setBatchName(e.target.value)}
						readOnly={user?.role === UserRole.TEACHER}
						disabled={user?.role === UserRole.TEACHER}
						slotProps={{
							input: {
								maxLength: 40,
								required: true,
							},
						}}
						onBlur={handleSubmit}
						error={!isBatchNameValid && batchName?.length > 0}
					/>
					{!isBatchNameValid && batchName?.length > 0 && (
						<Typography
							color='danger'
							sx={{
								fontSize: ".5rem",
							}}
						>
							Batch Name must be between 1 and 64 characters.
						</Typography>
					)}
				</Box>
				<Box sx={{ width: "50%", mx: 1 }}>
					<Typography sx={{ textAlign: "start", mb: 1, fontWeight: "600" }}>Name of the course</Typography>
					<Input
						value={batchDetails?.data?.courseDetails[0]?.name}
						readOnly
						disabled
					/>
				</Box>
			</Box>
			<Box sx={{ my: 4, display: "flex", alignItems: "center", justifyContent: "space-between" }}>
			<Box sx={{ width: "50%", mx: 1 }}>
					<Typography sx={{ textAlign: "start", mb: 1, fontWeight: "600" }}>Start Date</Typography>
					<Input
						type='date'
						readOnly={user?.role === UserRole.TEACHER}
						disabled={user?.role === UserRole.TEACHER}
						onChange={(e) => setStartDate(e.target.value)}
						onBlur={handleSubmit}
						value={convertToInputDate(startDate) || ""}
					/>
				</Box>
				<Box sx={{ width: "50%", mx: 1 }}>
					<Typography sx={{ textAlign: "start", mb: 1, fontWeight: "600" }}>End date</Typography>
					<Input
						type='date'
						readOnly={user?.role === UserRole.TEACHER}
						disabled={user?.role === UserRole.TEACHER}
						slotProps={{
							input: {
								min: DateTime.fromISO(startDate).plus({ day: 2 }).toFormat("yyyy-MM-dd"),
							},
						}}
						onChange={(e) => setEndDate(e.target.value)}
						onBlur={handleSubmit}
						value={convertToInputDate(endDate) || ""}
					/>
				</Box>
			</Box>
		</Box>
	);
};

export default BatchDetails;

import { Box, Button, Dropdown, Input, Menu, MenuButton, MenuItem, Modal, ModalClose, ModalDialog, Select, Typography } from "@mui/joy";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { UserRole } from "../../../../../Constants/user-role";
import { ReactComponent as TeacherSvg } from "../../../../../Assets/teacher.svg";
import { getFirstLetterCapitalized } from "../../../../../helpers/text-formatter";
import AddTeacher from "./add-new-teacher";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import { useDispatch } from "react-redux";
import { fetchTeacherByBatchId, fetchTeachersByTenant } from "../../../../../Store/actions/userAction";
import { unassignTeacherThunk } from "../../../../../Store/actions/authenticateAction";
import { fetchBatchByIdThunk } from "../../../../../Store/actions/batchAction";
import { addTeacherInfo, removeTeacherInfo, setFormFilled } from "../../../../../Store/slices/batchSlice";
import { batchType } from "../../../../../Constants/batch-tabs";
import Loader from "../../../../utils/Loader";
import { studentStatusColor } from "../../../../../helpers/styleConfig";
import { API_URL } from "../../../../../Config";
import axios from "axios";

const TeacherDetails = ({ batchId }) => {
	const [isOpen, setIsOpen] = useState(false);
	const [inviteLoader, setInviteLoader] = useState(false);
	const [loading, setLoading] = useState(false);
	const [unassignModal, setUnassignModal] = useState(false);
	const [instructorId, setInstructorId] = useState(null);

	const { batchDetails, teacherInfos, selectedTenant, teacherLoading } = useSelector((state) => state.batch);
	const { user } = useSelector((state) => state.user);

	const dispatch = useDispatch();

	useEffect(() => {
		dispatch(fetchTeacherByBatchId(batchId || batchDetails?.data?.batch_id));
		dispatch(fetchTeachersByTenant(user?.tenant_id || selectedTenant || batchDetails?.data?.courseDetails[0]?.tenant_id));

		if (!batchId) {
			dispatch(setFormFilled({ tab: batchType.teacherDetails, filled: true }));
		}
	}, [batchId]);
	console.log(teacherInfos);
	const renderCreateTeacherModal = () => {
		return (
			<AddTeacher
				setIsOpen={setIsOpen}
				isOpen={isOpen}
				batchId={batchId}
			/>
		);
	};

	const handleOpenUnassignModal = (e, instructorId) => {
		e.stopPropagation();
		setUnassignModal(true);
		setInstructorId(instructorId);
	};

	const handleUnassignTeacher = async () => {
		const teacherData = {
			batch_id: batchId || batchDetails?.data?.batch_id,
			instructor_id: instructorId,
		};
		setLoading(true);
		const response = await dispatch(unassignTeacherThunk(teacherData));
		if (response.payload.success) {
			dispatch(removeTeacherInfo(instructorId));
			setUnassignModal(false);
			setLoading(false);
			setInstructorId(null);
		}
	};

	const handleSendTeacherInvite = async () => {
		try {
			setInviteLoader(true);
			const batch_id = batchId || batchDetails?.data?.batch_id;
			const response = await axios.post(`${API_URL}/instructor/send-invite`, {
				batch_id,
			});
			if (response.data.success) {
				dispatch(fetchTeacherByBatchId(batch_id));
			}
			setInviteLoader(false);
		} catch (error) {
			console.error(error);
		}
	};

	const renderUnassignModal = () => {
		return (
			<Modal
				open={unassignModal}
				onClose={() => {
					setUnassignModal(false);
					setInstructorId(null);
				}}
			>
				<ModalDialog>
					<ModalClose />
					<Typography level='h4'>Confirmation</Typography>
					<Typography level='h6'>Are you sure you want to unassign this teacher?</Typography>
					<Box sx={{ display: "flex", justifyContent: "space-evenly" }}>
						<Button
							variant='solid'
							color='primary'
							onClick={() => setUnassignModal(false)}
						>
							No
						</Button>
						<Button
							variant='outlined'
							color='danger'
							loading={loading}
							onClick={handleUnassignTeacher}
						>
							Yes
						</Button>
					</Box>
				</ModalDialog>
			</Modal>
		);
	};

	const getStatus = (status) => {
		switch (status) {
			case "PENDING":
				return "To be invited";
			case "COMPLETED":
				return "Invited";
			default:
				return "To be invited";
		}
	};

	const renderTeacherCard = (teacher) => {
		return (
			<Box
				sx={{
					backgroundColor: "#f4f4f4",
					borderRadius: "sm",
					display: "flex",
					alignItems: "center",
					justifyContent: "space-between",
					p: ".8rem 1rem",
					my: 1,
				}}
				key={teacher.instructors.instructor_id}
			>
				<Box
					sx={{
						display: "flex",
						alignItems: "center",
						gap: 1,
					}}
				>
					<TeacherSvg
						style={{
							width: "20px",
							height: "20px",
						}}
					/>
					<Typography
						sx={{ color: "#3838f1", fontWeight: "600", maxWidth: 500 }}
						noWrap
					>
						{getFirstLetterCapitalized(teacher?.instructors?.name)}
					</Typography>
				</Box>
				<Box
					sx={{
						display: "flex",
						alignItems: "center",
						gap: 2,
					}}
				>
					<Typography
						sx={{
							fontWeight: 600,
							textAlign: "right",
							fontsize: "14px",
							color: studentStatusColor[getStatus(teacher?.invite_status)],
						}}
					>
						{getStatus(teacher?.invite_status)}
					</Typography>
					<Typography
						width={200}
						noWrap
						sx={{
							mr: 1,
							textAlign: "left",
						}}
					>
						{teacher?.instructors?.email}
					</Typography>
					{user?.role !== UserRole.TEACHER && (
						<Dropdown>
							<MenuButton
								sx={{
									backgroundColor: "transparent",
									border: "none",
									cursor: "pointer",
									padding: 0,
								}}
								onClick={(e) => {
									e.stopPropagation();
								}}
							>
								<MoreHorizIcon />
							</MenuButton>
							<Menu
								modifiers={[
									{
										name: "offset",
										options: {
											offset: ({ placement }) => {
												if (placement.includes("end")) {
													return [8, 20];
												}
												return [50, -20];
											},
										},
									},
								]}
							>
								<MenuItem
									color='danger'
									onClick={(e) => handleOpenUnassignModal(e, teacher.instructors.instructor_id)}
								>
									Unassign Teacher
								</MenuItem>
							</Menu>
						</Dropdown>
					)}
				</Box>
			</Box>
		);
	};

	const renderTeachers = () => {
		if (teacherLoading) {
			return (
				<Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", height: "30vh", borderRadius: "8px", bgcolor: "#f4f4f4", p: 2, mt: 2 }}>
					<Loader />
				</Box>
			);
		} else if (teacherInfos?.data?.length > 0) {
			return teacherInfos?.data?.map((teacher) => {
				return renderTeacherCard(teacher);
			});
		} else {
			return (
				<Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", height: "30vh", borderRadius: "8px", bgcolor: "#f4f4f4", p: 2, mt: 2 }}>
					<Typography
						level='h6'
						sx={{ color: "#000", fontWeight: 500 }}
					>
						No teachers Found
					</Typography>
				</Box>
			);
		}
	};

	return (
		<Box
			sx={{
				display: "grid",
			}}
		>
			<Box sx={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
				<Typography level='h5'>Teachers</Typography>
				{user?.role !== UserRole.TEACHER && (
					<Box
						sx={{
							display: "flex",
							alignItems: "center",
							gap: 2,
						}}
					>
						{teacherInfos?.data?.length > 0 && (
							<Button
								variant='outlined'
								sx={{ bgcolor: "white", color: "#3838f1", border: "1px solid #3838f1" }}
								loading={inviteLoader}
								disabled={inviteLoader}
								onClick={handleSendTeacherInvite}
							>
								Send Invites
							</Button>
						)}
						<Button
							variant='primary'
							onClick={() => setIsOpen(true)}
							sx={{ display: "flex", justifySelf: "flex-end", alignItems: "center", gap: 1, backgroundColor: "#3838F1" }}
						>
							<Typography
								sx={{ width: "fit-content", color: "white" }}
								level='body-sm'
							>
								Add a Teacher
							</Typography>
						</Button>
					</Box>
				)}
			</Box>
			<Box sx={{ borderBottom: "1px solid black", my: 1 }}></Box>
			{renderTeachers()}
			{unassignModal && user?.role !== UserRole.TEACHER && renderUnassignModal()}
			{user?.role !== UserRole.TEACHER && renderCreateTeacherModal()}
		</Box>
	);
};

export default TeacherDetails;

import { Textarea, Button, Input } from "@mui/joy";
import { Box, IconButton, Typography, Chip } from "@mui/material";
import React, { useState } from "react";
import UploadIcon from "@mui/icons-material/Upload";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
import { addManualTaskThunk } from "../../../../Store/actions/taskAction";
import { addTask } from "../../../../Store/slices/taskDetailsSlice";

const DocForm = ({ setOpen }) => {
	const [taskTitle, setTaskTitle] = useState("");
	const [objective, setObjective] = useState("");
	const [selectedPoint, setSelectedPoint] = useState(20);
	const [files, setFiles] = useState([]);
	const [loading, setLoading] = useState(false);

	const location = useLocation();
	const dispatch = useDispatch();

	const node_id = location.pathname.split("/")[4];

	const handleFileChange = (e) => {
		const selectedFiles = Array.from(e.target.files);
		if (selectedFiles.length + files.length > 4) {
			return; // Max 4 files
		}
		let totalSize = selectedFiles.reduce((acc, file) => acc + file.size, 0);
		if (totalSize > 50000000) {
			return; // Max 50MB total
		}
		setFiles((prevFiles) => [...prevFiles, ...selectedFiles]);
	};

	const handleSubmit = async () => {
		if (taskTitle === "" || files.length === 0) return;
		// Add task to the list
		const task = new FormData();
		task.append("task_title", taskTitle);
		task.append("question", objective);
		task.append("points", selectedPoint);
		task.append("node_id", node_id);
		task.append("type", "DOC");
		files.forEach((file) => {
			task.append("docs", file);
		});

		setLoading(true);
		const response = await dispatch(addManualTaskThunk(task));

		if (response?.payload?.data) {
			setLoading(false);
			dispatch(addTask(response?.payload?.data));
			setOpen(false);
		}
	};

	const handleFileRemove = (index) => {
		const newFiles = files.filter((file, i) => i !== index);
		setFiles(newFiles);
	};

	return (
		<Box sx={{ display: "flex", flexDirection: "column", height: "100%" }}>
			<Box sx={{ width: "100%", display: "flex", flexDirection: "column", gap: 2, mt: 1 }}>
				<Box
					sx={{
						maxHeight: "35vh",
						overflowY: "auto",
						px: 2,
					}}
				>
					<Box>
						<Typography
							level='h6'
							sx={{ mb: 1, color: "#050519", fontSize: "14px", fontWeight: "600" }}
						>
							Task Title
						</Typography>
						<Input
							label='Task title'
							type='text'
							slotProps={{
								input: {
									maxLength: 64,
								},
							}}
							value={taskTitle}
							onChange={(e) => setTaskTitle(e.target.value)}
							sx={{ width: "100%" }}
							placeholder='e.g. Binary Search'
							required
							// error={taskTitle?.trim() === "" ? "Please enter the task title" : ""}
						/>
					</Box>
					<Box
						sx={{
							my: 2,
						}}
					>
						<Typography
							level='h6'
							sx={{ mb: 1, color: "#050519", fontSize: "14px", fontWeight: "600" }}
						>
							Task Description
						</Typography>
						<Textarea
							label='Objective'
							value={objective}
							onChange={(e) => setObjective(e.target.value)}
							minRows={5}
							maxRows={10}
							type='text'
							slotProps={{
								textarea: {
									maxLength: 1024,
								},
							}}
							sx={{ width: "100%" }}
							placeholder='e.g. Compiler - Assembler Differences'
							required
							// error={objective?.trim() === "" ? "Please enter the objective" : ""}
						/>
					</Box>
					<Box
						sx={{
							display: "flex",
							flexDirection: "column",
							gap: 1,
						}}
					>
						<Typography
							level='h6'
							sx={{ color: "#050519", fontSize: "14px", fontWeight: "600" }}
						>
							Documents
						</Typography>
						<Box
							sx={{
								display: "flex",
								alignItems: "center",
								border: "1px solid rgba(0,0,0,0.25)",
								borderRadius: "8px",
								p: 0.5,
							}}
						>
							<IconButton
								component='label'
								color='primary'
								sx={{
									borderRadius: "8px",

									width: "100%",
								}}
							>
								<Input
									type='file'
									slotProps={{
										input: {
											accept: ".pdf,.doc,.docx,.xlsx,.csv,.ppt,.pptx",
											multiple: true,
										},
									}}
									sx={{ display: "none" }}
									onChange={handleFileChange}
								/>
								<UploadIcon />
								<Typography sx={{ marginX: 2 }}>{"Upload PDFs (max 4 files, max 50MB total)"}</Typography>
							</IconButton>
						</Box>
						{files.length > 0 && (
							<Box sx={{ display: "flex", gap: 1, my: 1, flexWrap: "wrap" }}>
								{files.map((file, index) => {
									return (
										<Chip
											label={file.name}
											onDelete={() => handleFileRemove(index)}
											sx={{
												maxWidth: "200px",
											}}
											color='info'
										/>
									);
								})}
							</Box>
						)}
					</Box>
					<Box>
						<Typography
							level='h6'
							sx={{ my: 1, color: "#050519", fontSize: "14px", fontWeight: "600" }}
						>
							Points
						</Typography>
						<Box sx={{ display: "flex", gap: 1 }}>
							{[1, 5, 10, 15, 20, 25].map((point) => (
								<Button
									key={point}
									variant='outlined'
									color='neutral'
									sx={{
										bgcolor: `${selectedPoint === point ? "#000" : "white"}`,
										color: `${selectedPoint === point ? "white" : "black"}`,
										"&:hover": {
											bgcolor: `${selectedPoint === point ? "#000" : "#f4f4f4"}`, // Change "gray" to your desired hover color
											color: `${selectedPoint === point ? "white" : "black"}`,
										},
									}}
									onClick={() => setSelectedPoint(point)}
								>
									{point}
								</Button>
							))}
						</Box>
					</Box>
					<Box
						sx={{
							display: "flex",
							justifyContent: "flex-end",
							alignItems: "center",
							width: "100%",
							my: 1,
						}}
					>
						<Button
							sx={{ mb: 1, bgcolor: "#3838f1", color: "white", alignSelf: "flex-end" }}
							color='primary'
							disabled={taskTitle === "" || files.length === 0}
							onClick={handleSubmit}
							loading={loading}
						>
							Add Task
						</Button>
					</Box>
				</Box>
			</Box>
		</Box>
	);
};

export default DocForm;

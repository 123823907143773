import axios from "axios";
import { API_URL } from "../../Config";

const fetchToken = () => {
	return localStorage.getItem("token");
};

export const getAllTeachersByTenantId = async (tenantId) => {
	const response = await axios.get(`${API_URL}/instructor?tenant_id=${tenantId}`, {
		headers: {
			Authorization: `Bearer ${fetchToken()}`,
		},
	});
	return response.data;
};

export const getTeachersByBatchId = async (batchId) => {
	const response = await axios.get(`${API_URL}/instructor/admin/${batchId}`, {
		headers: {
			Authorization: `Bearer ${fetchToken()}`,
		},
	});
	return response.data;
};

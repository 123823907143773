import { createSlice } from "@reduxjs/toolkit";
import { fetchStudentByIdThunk, fetchStudentSubmissionThunk } from "../actions/batchAction";

const initialState = {
	studentInfo: {},
	studentLoading: false,
	studentError: null,
	submissionInfo: null,
	submissionLoading: false,
	submissionError: null,
};

const studentSlice = createSlice({
	name: "student",
	initialState,
	extraReducers: (builder) => {
		builder
			.addCase(fetchStudentByIdThunk.pending, (state) => {
				state.studentLoading = true;
				state.studentError = null;
			})
			.addCase(fetchStudentByIdThunk.fulfilled, (state, action) => {
				state.studentLoading = false;
				state.studentInfo = action.payload;
				state.studentError = null;
			})
			.addCase(fetchStudentByIdThunk.rejected, (state, action) => {
				state.studentLoading = false;
				state.studentError = action.payload;
			});

		builder
			.addCase(fetchStudentSubmissionThunk.pending, (state) => {
				state.submissionInfo = null;
				state.submissionLoading = true;
				state.submissionError = null;
			})
			.addCase(fetchStudentSubmissionThunk.fulfilled, (state, action) => {
				state.submissionLoading = false;
				state.submissionInfo = action.payload;
				state.submissionError = null;
			})
			.addCase(fetchStudentSubmissionThunk.rejected, (state, action) => {
				state.submissionLoading = false;
				state.submissionError = action.payload;
			});
	},
});

export default studentSlice.reducer;

import { Box, Typography } from "@mui/joy";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import ReactPlayer from "react-player";

const VidTask = ({ task }) => {
	const [videoUrl, setVideoUrl] = useState(task?.actual_task?.metadata?.video?.url);
	return (
		<Box>
			<Typography
						level='h6'
						sx={{ color: "#000", fontSize: "14px", fontWeight: "700", marginBottom:"1rem" }}
					>
				Video
			</Typography>
			<Box
				sx={{
					borderRadius: "8px",
					padding: "1rem",
					backgroundColor: "#f4f4f4",
					height: "200px",
				}}
			>
				{videoUrl ? (
					<ReactPlayer
						url={videoUrl}
						controls
						width='100%'
						height='100%'
						style={{ borderRadius: "8px", height: "100%", backgroundColor: "black" }}
					/>
				) : (
					<Box>No Video Found</Box>
				)}
			</Box>
		</Box>
	);
};

export default VidTask;
import React, { useState } from "react";
import { Box, Button, Input, Typography } from "@mui/joy";
import { useDispatch } from "react-redux";
import { showToast } from "../../../../../Store/slices/commonSlice";
import { ToastType } from "../../../../../Constants/toast-type";
import { updateStudentThunk } from "../../../../../Store/actions/studentAction";
import { updateStudentData } from "../../../../../Store/slices/batchSlice";

const EditStudentDetails = ({ student, setIsEditModalOpen }) => {
	const [studentEmail, setStudentEmail] = useState(student.email);
	const [studentName, setStudentName] = useState(student.name);
	const [studentPhone, setStudentPhone] = useState(student.mobile_number);

	const dispatch = useDispatch();

	const handleEditStudent = async () => {
		if (!studentName || !studentEmail) {
			dispatch(showToast({ toastMessage: "Please fill all the fields", toastType: ToastType.ERROR }));
			return;
		}

		if (studentEmail === student.email && studentName === student.name) {
			dispatch(showToast({ toastMessage: "No changes to update", toastType: ToastType.ERROR }));
			return;
		}
		dispatch(updateStudentData({ student_id: student.student_id, name: studentName, email: studentEmail, mobile_number: studentPhone, college_details: student.college_details }));
		setIsEditModalOpen("");
		dispatch(
			updateStudentThunk({
				studentId: student.student_id,
				studentData: { name: studentName, email: studentEmail, mobile_number: studentPhone },
			})
		);
		dispatch(showToast({ toastMessage: "Student details updated successfully", toastType: ToastType.SUCCESS }));
	};

	const renderStudentDetails = () => {
		return (
			<Box sx={{ display: "flex", flexDirection: "column" }}>
				<Box
					sx={{
						my: 2,
					}}
				>
					<Box
						sx={{
							display: "flex",
							gap: 2,
							justifyContent: "space-between",
						}}
					>
						<Box
							sx={{
								width: "100%",
							}}
						>
							<Typography sx={{ textAlign: "start", mb: 1, fontWeight: "600" }}>Student Name</Typography>
							<Input
								value={studentName}
								placeholder='Enter Student Name'
								onChange={(e) => setStudentName(e.target.value)}
							/>
						</Box>
						{student?.college_details?.college_name && (
							<Box
								sx={{
									width: "100%",
								}}
							>
								<Typography sx={{ textAlign: "start", mb: 1, fontWeight: "600" }}>College Name</Typography>
								<Input
									value={student?.college_details?.college_name}
									placeholder='Enter College Name'
									readOnly={true}
								/>
							</Box>
						)}
					</Box>
				</Box>
				<Box
					sx={{
						my: 2,
					}}
				>
					<Typography sx={{ textAlign: "start", mb: 1, fontWeight: "600" }}>Student Email</Typography>
					<Input
						value={studentEmail}
						placeholder='Enter Student Email'
						onChange={(e) => setStudentEmail(e.target.value)}
					/>
				</Box>
				<Box
					sx={{
						my: 2,
					}}
				>
					<Typography sx={{ textAlign: "start", mb: 1, fontWeight: "600" }}>Student Phone</Typography>
					<Input
						value={studentPhone}
						placeholder='Enter Student Phone'
						onChange={(e) => setStudentPhone(e.target.value)}
					/>
				</Box>
				<Box
					sx={{
						display: "flex",
						justifyContent: "flex-end",
					}}
				>
					<Button
						sx={{ mb: 1, bgcolor: "#3838f1", color: "white" }}
						color='primary'
						onClick={handleEditStudent}
					>
						Save
					</Button>
				</Box>
			</Box>
		);
	};

	return <Box>{renderStudentDetails()}</Box>;
};

export default EditStudentDetails;

import React, { useEffect, useState } from "react";
import WithAuth from "../utils/WithAuth";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
import { fetchStudentByIdThunk } from "../../Store/actions/batchAction";
import { useSelector } from "react-redux";
import { Box, Tab, TabList, Tabs, Typography } from "@mui/joy";
import StudentDetails from "./student-details/student-info";
import StudentSubmission from "./student-submission/student-submission";
import { getFirstLetterCapitalized } from "../../helpers/text-formatter";

const Submissions = () => {
	const [activeTab, setActiveTab] = useState("submissions");

	const { studentInfo, studentLoading, studentError } = useSelector((state) => state.student);

	const dispatch = useDispatch();
	const location = useLocation();

	const student_id = location.pathname.split("/")[4];
	useEffect(() => {
		dispatch(fetchStudentByIdThunk(student_id));
	}, [student_id]);

	const handleTabChange = (event, newValue) => {
		setActiveTab(newValue);
	};

	const renderTabsInfo = (activeTab) => {
		switch (activeTab) {
			case "details":
				return <StudentDetails />;
			case "submissions":
				return <StudentSubmission />;
			default:
				return <StudentDetails />;
		}
	};

	return (
		<Box sx={{ width: "100%", maxWidth: 1200, margin: "auto", p: 2 }}>
			<Box sx={{ display: "flex", justifyContent: "space-between", mb: 2 }}>
				<Typography level='h4'>{studentInfo?.data?.length > 0 && getFirstLetterCapitalized(studentInfo.data[0].name)}</Typography>
			</Box>

			<Tabs
				value={activeTab}
				onChange={handleTabChange}
				sx={{
					borderRadius: "8px",
					width: "fit-content",
				}}
			>
				<TabList>
					<Tab value='details'>Student Details</Tab>
					<Tab value='submissions'>Submissions</Tab>
				</TabList>
			</Tabs>

			<Box
				sx={{
					p: 2,
					border: "1px solid",
					borderColor: "divider",
					maxHeight: "100vh",
					borderRadius: "sm",
					background: "white",
				}}
			>
				{renderTabsInfo(activeTab)}
			</Box>
		</Box>
	);
};

export default WithAuth(Submissions);

import { createAsyncThunk } from "@reduxjs/toolkit";
import { getAllTeachersByTenantId, getTeachersByBatchId } from "../services/userApiService";

export const fetchTeachersByTenant = createAsyncThunk("teacher/fetchTeachersByTenant", async (tenantId, { rejectWithValue }) => {
	try {
		const response = await getAllTeachersByTenantId(tenantId);
		return response;
	} catch (error) {
		return rejectWithValue(error.message || "Error in fetching teachers");
	}
});

export const fetchTeacherByBatchId = createAsyncThunk("teacher/fetchTeacherByBatchId", async (batchId, { rejectWithValue }) => {
	try {
		const response = await getTeachersByBatchId(batchId);
		return response;
	} catch (error) {
		return rejectWithValue(error.message || "Error in fetching teachers");
	}
});

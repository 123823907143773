import { Textarea, Button, Input } from "@mui/joy";
import { Box, IconButton, Typography, Chip } from "@mui/material";
import React, { useState, useEffect } from "react";
import UploadIcon from "@mui/icons-material/Upload";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
import { addManualTaskThunk } from "../../../../Store/actions/taskAction";
import { addTask } from "../../../../Store/slices/taskDetailsSlice";

const VidForm = ({ setOpen }) => {
    const [taskTitle, setTaskTitle] = useState("");
    const [videoUrl, setVideoUrl] = useState("");
    const [selectedPoint, setSelectedPoint] = useState(20);
    const [loading, setLoading] = useState(false);
    const [duration_minutes, setDuration] = useState();
    const [urlError, setUrlError] = useState(""); // State for URL error message


    const location = useLocation();
    const dispatch = useDispatch();

    const node_id = location.pathname.split("/")[4];

    // Function to extract video ID from URL (both YouTube and Vimeo)
    const getVideoId = (url) => {
        try {
            const urlObj = new URL(url);

            // Handle Vimeo URLs
            if (urlObj.hostname.includes('vimeo.com')) {
                const segments = urlObj.pathname.split('/');
                for (let segment of segments) {
                    if (/^\d+$/.test(segment)) {
                        return { platform: 'vimeo', id: segment };
                    }
                }
            }

            // // Handle YouTube URLs
            // if (urlObj.hostname.includes('youtube.com') || urlObj.hostname.includes('youtu.be')) {
            //     let videoId;
            //     if (urlObj.hostname.includes('youtu.be')) {
            //         videoId = urlObj.pathname.slice(1);
            //     } else {
            //         videoId = urlObj.searchParams.get('v');
            //     }
            //     if (videoId) {
            //         return { platform: 'youtube', id: videoId };
            //     }
            // }
        } catch (e) {
            console.error("Invalid URL:", e);
            return null;
        }
        return null;
    };

    // Function to fetch video duration
    const fetchVideoDuration = async (url) => {
        const videoInfo = getVideoId(url);
        if (!videoInfo) {
            console.error("Invalid video URL or ID not found");
            return;
        }

        try {
            if (videoInfo.platform === 'vimeo') {
                // Existing Vimeo code works fine
                const response = await fetch(`https://vimeo.com/api/oembed.json?url=${encodeURIComponent(url)}`);
                const data = await response.json();
                if (data.duration) {
                    const totalSeconds = data.duration;
                    const hours = Math.floor(totalSeconds / 3600);
                    const minutes = Math.floor((totalSeconds % 3600) / 60);
                    const seconds = totalSeconds % 60;
                    // const formattedDuration = `${hours}:${String(minutes).padStart(2, '0')}:${String(seconds).padStart(2, '0')}`;
                    setDuration(minutes);
                }
            // } else if (videoInfo.platform === 'youtube') {
            //     // Create a hidden iframe
            //     const iframe = document.createElement('iframe');
            //     iframe.style.display = 'none';
            //     iframe.src = `https://www.youtube.com/embed/${videoInfo.id}?enablejsapi=1`;
            //     document.body.appendChild(iframe);

            //     const duration = await new Promise((resolve, reject) => {
            //         // Set a timeout of 5 seconds
            //         const timeoutId = setTimeout(() => {
            //             reject(new Error('Timeout getting video duration'));
            //         }, 5000);

            //         // Create YouTube player once iframe is loaded
            //         iframe.onload = () => {
            //             if (!window.YT || !window.YT.Player) {
            //                 clearTimeout(timeoutId);
            //                 reject(new Error('YouTube API not loaded'));
            //                 return;
            //             }

            //             const player = new window.YT.Player(iframe, {
            //                 events: {
            //                     onReady: (event) => {
            //                         clearTimeout(timeoutId);
            //                         const duration = event.target.getDuration();
            //                         resolve(duration);
            //                     },
            //                     onError: (error) => {
            //                         clearTimeout(timeoutId);
            //                         reject(error);
            //                     }
            //                 }
            //             });
            //         };
             //   });

                // // Format duration
                // const hours = Math.floor(duration / 3600);
                // const minutes = Math.floor((duration % 3600) / 60);
                // const seconds = Math.floor(duration % 60);
                // const formattedDuration = `${hours}:${String(minutes).padStart(2, '0')}:${String(seconds).padStart(2, '0')}`;
                // setDuration(formattedDuration);

                // // Clean up
                // document.body.removeChild(iframe);
            }
        } catch (error) {
            console.error("Error fetching video duration:", error);
            setDuration('');
        }
    };

    // // Add this function to ensure YouTube API is loaded
    // const loadYouTubeAPI = () => {
    //     return new Promise((resolve) => {
    //         // If API is already loaded
    //         if (window.YT && window.YT.Player) {
    //             resolve();
    //             return;
    //         }

    //         // If the script is already being loaded
    //         if (document.querySelector('script[src="https://www.youtube.com/iframe_api"]')) {
    //             window.onYouTubeIframeAPIReady = resolve;
    //             return;
    //         }

    //         // Load the API script
    //         const tag = document.createElement('script');
    //         tag.src = 'https://www.youtube.com/iframe_api';
    //         const firstScriptTag = document.getElementsByTagName('script')[0];
    //         firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);

    //         window.onYouTubeIframeAPIReady = resolve;
    //     });
    // };

    // Update useEffect to load YouTube API first
    useEffect(() => {
        if (videoUrl) {
            const videoInfo = getVideoId(videoUrl);
            if (videoInfo?.platform === 'vimeo') {
                fetchVideoDuration(videoUrl);
            }
        }
    }, [videoUrl]);

    const handleSubmit = async () => {
        if (taskTitle === "" || videoUrl === "") return;
        // Add task to the list
        const task = new FormData();
        task.append("task_title", taskTitle);
        task.append("videoUrl", videoUrl);
        task.append("duration_minutes", duration_minutes);
        task.append("points", selectedPoint);
        task.append("node_id", node_id);
        task.append("type", "VID");

        setLoading(true);
        const response = await dispatch(addManualTaskThunk(task));

        if (response?.payload?.data) {
            setLoading(false);
            dispatch(addTask(response?.payload?.data));
            setOpen(false);
        }
    };

    return (
        <Box sx={{ display: "flex", flexDirection: "column", height: "100%" }}>
            <Box sx={{ width: "100%", display: "flex", flexDirection: "column", gap: 2, mt: 1 }}>
                <Box
                    sx={{
                        maxHeight: "40vh",
                        overflowY: "auto",
                        px: 2,
                    }}
                >
                    <Box>
                        <Typography
                            level='h6'
                            sx={{ mb: 1, color: "#050519", fontSize: "14px", fontWeight: "600" }}
                        >
                            Video Title
                        </Typography>
                        <Input
                            label='Task title'
                            type='text'
                            slotProps={{
                                input: {
                                    maxLength: 64,
                                },
                            }}
                            value={taskTitle}
                            onChange={(e) => setTaskTitle(e.target.value)}
                            sx={{ width: "100%" }}
                            placeholder='e.g. Binary Search'
                            required
                            // error={taskTitle?.trim() === "" ? "Please enter the task title" : ""}
                        />
                    </Box>
                    <Box
                        sx={{
                            my: 2,
                        }}
                    >
                        <Typography
                            level='h6'
                            sx={{ mb: 1, color: "#050519", fontSize: "14px", fontWeight: "600" }}
                        >
                            Video URL
                        </Typography>
                        <Textarea
                            label='Video URL'
                            value={videoUrl}
                            onChange={(e) => {
                                const url = e.target.value;
                                // Check if the input is a valid URL
                                try {
                                    new URL(url);
                                    setVideoUrl(url);
                                    setUrlError(""); // Clear error if valid
                                } catch {
                                    // Set error message for invalid URL
                                    setVideoUrl("");
                                    setUrlError("Please enter a valid URL.");
                                }
                            }}
                            type='text'
                            slotProps={{
                                textarea: {
                                    maxLength: 64,
                                },
                            }}
                            sx={{ width: "100%" }}
                            placeholder='e.g. https://vimeo.com/123456789'
                            required
                            // error={videoUrl?.trim() === "" ? "Please enter the url" : ""}
                        />
                        {urlError && (
                        <Typography sx={{ color: "red", fontSize: "12px" }}>
                            {urlError}
                        </Typography>
                        )}
                    </Box>
                    {/* <Box>
						<Typography
                            level='h6'
							sx={{ mb: 1, color: "#050519", fontSize: "14px", fontWeight: "600" }}
						>
							Duration
						</Typography>
						<Input
							placeholder='e.g. 00:30:00'
							label='Duration'
							value={duration}
							type='time'
							slotProps={{
								input: {
									step: 1,
								},
							}}
							onChange={(e) => setDuration(e.target.value)}
						/>
					</Box> */}
                    <Box>

                        <Typography
                            level='h6'
                            sx={{ mb: 1, color: "#050519", fontSize: "14px", fontWeight: "600" }}
                        >
                            Duration (in minutes)
                        </Typography>
                        <Input
                            type="number"
                            placeholder='e.g. 30'
                            value={duration_minutes}
                            onChange={(e) => {
                                // Only allow positive numbers
                                const value = Math.max(0, parseInt(e.target.value) || 0);
                                setDuration(value);
                            }}
                            slotProps={{
                                input: {
                                    min: 0,
                                }
                            }}
                            sx={{
                                width: "100px",  // Make the box smaller
                                '& input': {
                                    textAlign: 'center'  // Center the number
                                }
                            }}
                        />
                    </Box>
                    {/* <Box>
                        <Typography
                            level='h6'
                            sx={{ mb: 1, color: "#050519", fontSize: "14px", fontWeight: "600" }}
                        >
                            Duration (HH:mm:ss)
                        </Typography>
                        <Input
                            placeholder='e.g. 0:30:00'
                            label='Duration'
                            value={duration}
                            disabled  // Since duration is auto-calculated
                            sx={{ width: "100%" }}
                            required
                        />
                    </Box> */}
                </Box>

                <Box>
                    <Typography
                        level='h6'
                        sx={{ mb: 1, color: "#050519", fontSize: "14px", fontWeight: "600" }}
                    >
                        Points
                    </Typography>
                    <Box sx={{ display: "flex", gap: 1 }}>
                        {[1, 5, 10, 15, 20, 25].map((point) => (
                            <Button
                                key={point}
                                variant='outlined'
                                color='neutral'
                                sx={{
                                    bgcolor: `${selectedPoint === point ? "#000" : "white"}`,
                                    color: `${selectedPoint === point ? "white" : "black"}`,
                                    "&:hover": {
                                        bgcolor: `${selectedPoint === point ? "#000" : "#f4f4f4"}`, // Change "gray" to your desired hover color
                                        color: `${selectedPoint === point ? "white" : "black"}`,
                                    },
                                }}
                                onClick={() => setSelectedPoint(point)}
                            >
                                {point}
                            </Button>
                        ))}
                    </Box>
                </Box>
            </Box>
            <Button
                sx={{ mb: 1, bgcolor: "#3838f1", color: "white", alignSelf: "flex-end" }}
                color='primary'
                disabled={taskTitle === "" || videoUrl === ""}
                onClick={handleSubmit}
                loading={loading}
            >
                Add Task
            </Button>
        </Box>
    );
};

export default VidForm;

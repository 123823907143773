import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Navigate, useLocation, useNavigate } from "react-router-dom";
import { UserRole } from "../../Constants/user-role";
import { Box, Button, Dropdown, Menu, MenuButton, MenuItem, ToggleButtonGroup, Typography } from "@mui/joy";
import Logo from "../../Assets/interleap-logo.png";
import BackButton from "./BackButton";
import { useDispatch } from "react-redux";
import { setCurrentTab } from "../../Store/slices/commonSlice.js";
import GenericAvatar from "../../Assets/Avatar_Generic.png";
import { removeUserData } from "../../Store/slices/userSlice.js";
import { getTenantByIdThunk } from "../../Store/actions/tenantAction.js";

const WithAuth = (Component, allowedRoles = []) => {
	return function WithAuth(props) {
		const { user, isAuthenticated, adminLogo } = useSelector((state) => state.user);
		const { currentTab } = useSelector((state) => state.common);

		const dispatch = useDispatch();

		if (user?.role === UserRole.TEACHER) {
			dispatch(setCurrentTab("batches"));
		}
		const navigate = useNavigate();

		const location = useLocation();

		useEffect(() => {
			const path = location.pathname.split("/")[1];
			if (path === "course" || path === "batches") {
				dispatch(setCurrentTab(path));
			}
		}, [location.pathname, dispatch]);

		useEffect(() => {
			if (user?.tenant_id) {
				dispatch(getTenantByIdThunk(user?.tenant_id));
			}
		}, []);

		//if user is a teacher only allow /batches and /batches/:batchId and if the route is /batches/addNewBatch redirect to /batches

		if (!isAuthenticated || localStorage.getItem("token") === null) {
			return <Navigate to='/login' />;
		}
		if (user?.role === UserRole.TEACHER) {
			if (location.pathname === "/batches/addNewBatch") {
				return <Navigate to='/batches' />;
			} else if (location.pathname !== "/batches" && !location.pathname.includes("/batches/")) {
				return <Navigate to='/batches' />;
			}
		}

		const handleLogout = () => {
			localStorage.removeItem("token");
			dispatch(removeUserData());
			navigate("/login");
		};

		const getLogo = () => {
			if (user?.tenant_id === null) return Logo;
			return adminLogo;
		};

		return (
			<div className='home-container'>
				<Box
					sx={{
						display: "flex",
						justifyContent: "space-between",
						alignItems: "center",
					}}
				>
					<Box
						sx={{
							width: "100px",
							height: "auto",
							cursor: "pointer",
						}}
						onClick={() => navigate("/")}
					>
						<img
							src={getLogo()}
							style={{
								maxWidth: "100%",
							}}
						/>
					</Box>
					<ToggleButtonGroup
						value={currentTab}
						onChange={(event, newValue) => {
							if (newValue) {
								// setValue(newValue);
								dispatch(setCurrentTab(newValue));
								navigate(`/${newValue}`);
							}
						}}
						sx={{ display: "flex", gap: 2, alignItems: "center", justifyContent: "center", border: "none" }}
					>
						{(user?.role === UserRole.ADMIN || user?.role === UserRole.TENANT) && (
							<>
								<Button
									className={`${currentTab === "course" ? "selected-toggle-button active" : "toggle-button"}`}
									value='course'
									variant='plain'
								>
									Courses
								</Button>

								{/* <Button
									className={`${currentTab === "partner" ? "selected-toggle-button" : "toggle-button"}`}
									value='partner'
								>
									Partners
								</Button> */}
							</>
						)}
						<Button
							className={`${currentTab === "batches" ? "selected-toggle-button active" : "toggle-button"}`}
							value='batches'
							variant='plain'
						>
							Batches
						</Button>
					</ToggleButtonGroup>
					<div style={{ gap: 2 }}>
						<Box
							sx={{
								display: "flex",
								alignItems: "center",
								gap: 2,
							}}
						>
							<Box
								sx={{
									display: "flex",
									flexDirection: "column",
									alignItems: "flex-end",
								}}
							>
								<Typography
									sx={{
										fontSize: "14px",
										fontWeight: "700",
										color: "#3838f1",
									}}
									noWrap
									maxWidth={200}
								>
									{user?.name}
								</Typography>
								<Typography
									sx={{
										fontSize: ".7rem",
										color: "rgba(0,0,0,.5)",
									}}
									noWrap
									maxWidth={200}
								>
									{user?.email}
								</Typography>
							</Box>
							<Dropdown>
								<MenuButton sx={{ width: "40px", height: "40px" }}>
									<div style={{ width: "40px", height: "40px" }}>
										<img
											src={GenericAvatar}
											alt=''
											style={{ width: "40px", height: "40px" }}
										/>
									</div>
								</MenuButton>
								<Menu
									placement='bottom-end'
									sx={{ borderRadius: 8, border: 0, mt: 1, width: "200px" }}
								>
									{/* <MenuItem
								// onClick={() => handleShowChatHistory(!showChatHistory)}
								>
									<p style={{ fontSize: "14px", fontWeight: "500" }}>Profile</p>
								</MenuItem> */}
									<MenuItem onClick={() => handleLogout()}>
										<p style={{ color: "#c53030", fontSize: "14px", fontWeight: "500" }}>Sign Out</p>
									</MenuItem>
								</Menu>
							</Dropdown>
						</Box>
					</div>
				</Box>
				<BackButton />
				<Component {...props} />
			</div>
		);
	};
};

export default WithAuth;

import { Box, Button, Input, Option, Select, Typography } from "@mui/joy";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchAllTenantsThunk, getCoursesByTenantIdThunk } from "../../../../Store/actions/tenantAction";
import { selectCourse, selectTenant, setFormFilled } from "../../../../Store/slices/batchSlice";
import { batchType } from "../../../../Constants/batch-tabs";
import { addBatchThunk } from "../../../../Store/actions/batchAction";
import { DateTime } from "luxon";
import { UserRole } from "../../../../Constants/user-role";

const BatchDetails = () => {
	const dispatch = useDispatch();
	const [startDate, setStartDate] = useState("");
	const [endDate, setEndDate] = useState("");
	const [batchName, setBatchName] = useState("");
	const [externalBatchId, setExternalBatchId] = useState("");
	const [hasError, setHasError] = useState(false);

	const { user } = useSelector((state) => state.user);
	const { tenants, selectedTenant, selectedCourse, courses, loading, batchDetails } = useSelector((state) => state.batch);

	useEffect(() => {
		if (user?.role === UserRole.ADMIN) dispatch(fetchAllTenantsThunk());
		else {
			dispatch(getCoursesByTenantIdThunk(user?.tenant_id));
			dispatch(selectTenant(user?.tenant_id));
		}
	}, [dispatch, user?.role, user?.tenant_id]);

	const handlePartnerChange = (event, newValue) => {
		const tenantId = newValue;
		dispatch(selectTenant(tenantId));
		if (tenantId) dispatch(getCoursesByTenantIdThunk(tenantId));
		dispatch(setFormFilled({ tab: batchType.batchDetails, filled: false }));
	};

	const handleCourseChange = (event, newValue) => {
		dispatch(selectCourse(newValue));
		if (batchName && selectedTenant && newValue && startDate && endDate) {
			const format = "d/M/yyyy";
			const utcEndDate = DateTime.fromISO(endDate, format).set({ hour: 23, minute: 59, second: 0, millisecond: 0 }).toUTC().toISO();
			const utcStartDate = DateTime.fromISO(startDate, format).toUTC().toISO();
			let instructor_id = null;
			if (user?.role === UserRole.TEACHER) instructor_id = user?.instructor_id;
			dispatch(addBatchThunk({ name: batchName, courses: [newValue], start_date: utcStartDate, end_date: utcEndDate, instructor_id, external_batch_id: externalBatchId }));
			dispatch(setFormFilled({ tab: batchType.batchDetails, filled: !!newValue }));
		}
	};

	const handleEndDateChange = (e) => {
		setEndDate(e.target.value);
		dispatch(setFormFilled({ tab: batchType.batchDetails, filled: false }));
	};

	const handleChangeName = (event) => {
		setBatchName(event.target.value);
		dispatch(setFormFilled({ tab: batchType.batchDetails, filled: false }));
	};

	const handleStartDate = (e) => {
		const inputDate = e.target.value;
		const currentDate = DateTime.now().toFormat("yyyy-MM-dd");

		// Check if the entered date is less than today's date
		if (inputDate < currentDate) {
			alert("You cannot select a past date."); // Notify the user
			setStartDate(currentDate); // reset to today's date
		}
	};

	const handleEndDate = (e) => {
		const inputDate = e.target.value;

		// Check if the entered date is less than today's date
		if (inputDate && inputDate < startDate) {
			alert("You cannot select a past date."); // Notify the user

			e.target.value = startDate;
			setEndDate(startDate); // Reset state to today's date
		} else {
			setEndDate(inputDate); // Update the start date if valid
		}
	};

	const isBatchNameValid = batchName.length > 0 && batchName.length <= 64;
	const isExternalBatchIdValid = /^[a-zA-Z0-9-_/]{1,32}$/.test(externalBatchId);

	return (
		<Box sx={{ display: "flex", flexDirection: "column" }}>
			<Box
				sx={{
					display: "flex",
					alignItems: "center",
					justifyContent: "space-between",
				}}
			>
				<Box sx={{ mx: 1, mb: 2, width: "50%" }}>
					<Typography sx={{ textAlign: "start", mb: 1, fontWeight: "600" }}>Batch Name</Typography>
					<Input
						value={batchName || batchDetails?.data?.name || ""}
						placeholder='Enter Batch Name'
						sx={{ mb: 2 }}
						slotProps={{
							input: {
								maxLength: 40,
								required: true,
							},
						}}
						onChange={handleChangeName}
						error={!isBatchNameValid && batchName.length > 0}
					/>
					{!isBatchNameValid && batchName.length > 0 && (
						<Typography
							color='danger'
							sx={{
								fontSize: ".5rem",
							}}
						>
							Batch Name must be between 1 and 64 characters.
						</Typography>
					)}
				</Box>
				<Box sx={{ mx: 1, mb: 2, width: "50%" }}>
					<Typography sx={{ textAlign: "start", mb: 1, fontWeight: "600" }}>External Batch Id</Typography>
					<Input
						value={externalBatchId || batchDetails?.data?.external_batch_id || ""}
						placeholder='Enter External Batch Id'
						sx={{
							mb: 2,
						}}
						slotProps={{
							input: {
								pattern: "[a-zA-Z0-9-_/]{1,16}",
								required: true,
								maxLength: 32,
							},
						}}
						onChange={(e) => setExternalBatchId(e.target.value)}
						error={!isExternalBatchIdValid && externalBatchId.length > 0}
					/>
					{!isExternalBatchIdValid && externalBatchId.length > 0 && (
						<Typography
							color='danger'
							sx={{
								fontSize: ".8rem",
								mt: -3,
								pt: 1,
								textAlign: "left",
							}}
						>
							External Batch ID must be alphanumeric and '-' , '_' , '/' are allowed.
						</Typography>
					)}
				</Box>
			</Box>

			<Box sx={{ my: 2, display: "flex", alignItems: "center", justifyContent: "space-between" }}>
				<Box sx={{ width: "50%", mx: 1 }}>
					<Typography sx={{ textAlign: "start", mb: 1, fontWeight: "600" }}>Start Date</Typography>
					<Input
						type='date'
						value={startDate || ""}
						disabled={loading || !batchName || !externalBatchId}
						slotProps={{
							input: {
								min: DateTime.now().toFormat("yyyy-MM-dd"),
							},
						}}
						onChange={(e) => setStartDate(e.target.value)}
						onBlur={handleStartDate}
					/>
				</Box>
				<Box sx={{ width: "50%", mx: 1 }}>
					<Typography sx={{ textAlign: "start", mb: 1, fontWeight: "600" }}>End date</Typography>
					<Input
						type='date'
						disabled={loading || !batchName || !startDate}
						slotProps={{
							input: {
								min: startDate,
							},
						}}
						value={endDate || ""}
						onChange={handleEndDateChange}
						onBlur={handleEndDate}
					/>
				</Box>
			</Box>

			<Box sx={{ my: 4, display: "flex", alignItems: "center", justifyContent: "space-between" }}>
				{user?.role === UserRole.ADMIN && (
					<Box sx={{ width: "50%", mx: 1 }}>
						<>
							<Typography sx={{ textAlign: "start", mb: 1, fontWeight: "600" }}>Partner</Typography>
							<Select
								placeholder='Select Partner'
								value={selectedTenant || ""}
								onChange={handlePartnerChange}
								disabled={loading || !batchName || !startDate || !endDate}
								sx={{ mb: 2 }}
							>
								{tenants?.data?.map((partner) => (
									<Option
										key={partner.tenant_id}
										value={partner.tenant_id}
									>
										{partner.name}
									</Option>
								))}
							</Select>
						</>
					</Box>
				)}
				<Box sx={{ width: "50%", mx: 1 }}>
					<Typography sx={{ textAlign: "start", mb: 1, fontWeight: "600" }}>Name of the course</Typography>
					<Select
						placeholder='Select Course'
						value={selectedCourse || ""}
						onChange={handleCourseChange}
						disabled={loading || !selectedTenant || !batchName || !startDate || !endDate}
						sx={{ mb: 2 }}
					>
						{courses?.data?.map((course) => (
							<Option
								key={course.course_id}
								value={course.course_id}
							>
								{course.name}
							</Option>
						))}
					</Select>
				</Box>
			</Box>
		</Box>
	);
};

export default BatchDetails;

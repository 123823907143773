import { createAsyncThunk } from "@reduxjs/toolkit";
import { updateStudent } from "../services/studentApiService";

export const updateStudentThunk = createAsyncThunk("student/updateStudent", async ({ studentId, studentData }, { rejectWithValue }) => {
	try {
		const response = await updateStudent(studentId, studentData);
		return response;
	} catch (error) {
		return rejectWithValue(error.message || "An error occurred while updating student.");
	}
});

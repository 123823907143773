import { createAsyncThunk } from "@reduxjs/toolkit";
import { createEventForBatch, fetchEventsByBatchId, deleteEventById, editEventForBatch } from "../services/batchEventApiService";

export const createEventForBatchThunk = createAsyncThunk("batchEvent/createEventForBatch", async (event, { rejectWithValue }) => {
	try {
		const response = await createEventForBatch(event);
		return response;
	} catch (error) {
		return rejectWithValue(error.message || "Error in adding event");
	}
});

export const fetchEventsByBatchIdThunk = createAsyncThunk("batchEvent/fetchEventsByBatchId", async (batchId, { rejectWithValue }) => {
	try {
		const response = await fetchEventsByBatchId(batchId);
		return response;
	} catch (error) {
		return rejectWithValue(error.response.data.message || "Error in fetching events");
	}
});

export const deleteEventForBatchThunk = createAsyncThunk("batchEvent/deleteEventForBatch", async (eventId, { rejectWithValue }) => {
	try {
		const response = await deleteEventById(eventId);
		return response;
	} catch (error) {
		return rejectWithValue(error.message || "Error in adding event");
	}
});

export const EditEventForBatchThunk = createAsyncThunk("batchEvent/EditEventForBatch", async (event, { rejectWithValue }) => {
	try {
		const response = await editEventForBatch(event);
		return response;
	} catch (error) {
		return rejectWithValue(error.message || "Error in Editing event");
	}
});
